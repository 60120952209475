<section #eventList class="event-list">
  <div class="event-list__row">
    <!-- per item -->
    <div *ngFor="let item of ListRes.infos" class="event-list__col">
      <div class="event-list__item">
        <div class="event-list__image">
          <a *ngIf="item.cUrl" href="{{item.cUrl}}" >
            <img src="{{item.cImg}}" alt="">
          </a>
          <a *ngIf="!item.cUrl" [routerLink]="['/event', item.cType,item.id]">
            <img src="{{item.cImg}}" alt="">
          </a>
        </div>
        <div class="event-list__category">
          <!-- <span>{{item.typeName}}</span> -->
          <!-- <div>&nbsp;</div> -->
        </div>
        <div class="event-list__content">
          <a *ngIf="item.cUrl" href="{{item.cUrl}}" >
            {{item.cName}}
          </a>
          <a *ngIf="!item.cUrl" [routerLink]="['/event', item.cType, item.id]">
            {{item.cName}}
          </a>
        </div>
        <!-- <span class="dateorange">{{item.cSdate | date: 'yyyy/MM/dd'}}~{{item.cEdate | date: 'yyyy/MM/dd'}}</span> -->
      </div>
    </div>
  </div>
</section>

<div class="process-step-button row">
  <div class="process-step-button__button-wrap">
    <a class="process-step-button__button process-step-button__button--cancel" (click)="goback()">回上一頁</a>
  </div>
</div>

<app-pagination *ngIf="totalDataCount>itemsPerPage" [currentPage]="ListReq.PageNum" [anchorElement]="eventList"
  [totalItems]="totalDataCount" [itemsPerPageData]="itemsPerPageData" (SelectPage)="goToPage($event)">
</app-pagination>
