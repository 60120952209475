<div class="product-background">
  <section class="product-content">
    <div class="bottom">
      <div class="product-content__fixed-info-wrap">
        <app-product-detail-info
          [isShow]="isInfoShow"
          [product]="!!product.label"
          [relatedNews]="relatedNews"
          (closeInfo)="isInfoShow = $event"
        >
        </app-product-detail-info>
      </div>
      <div
        *ngIf="product.label"
        class="product-content__label"
        [ngStyle]="{
          color: product.label.color,
          backgroundColor: product.label.backGroundColor
        }"
      >
        <!-- [ngClass]="{'product-content__label-news':isInfoShow}" -->
        {{ product.label.text }}
      </div>
      <div class="product-content__title-wrap">
        <!-- <h2 class="product-content__title">{{product.categoryName2}}商家產品</h2> -->
      </div>
      <section class="product-content__info">
        <div class="product-content__row">
          <div class="product-content__col product-content__col--image-section">
            <div class="out-of-stock-mask" *ngIf="product.isSoldOut">
              <div class="out-of-stock-mask__circle">
                <span class="out-of-stock-mask__text">已售完</span>
              </div>
            </div>
            <div style="display: flex; justify-content: center">
              <img *ngIf="product.img1" [src]="product.img1" alt="" />
            </div>
          </div>
          <div class="product-content__col product-content__col--text-section">
            <div>
              <div class="text-section__title-wrap">
                <h3 class="text-section__title">{{ product.name }}</h3>
                <!-- red label -->
                <span
                  *ngIf="false"
                  class="product-content__activity-label-wrap product-content__activity-label-wrap--first"
                  ><img
                    class="product-content__activity-label"
                    src="/assets/images/icon/product_red_label.svg"
                    alt=""
                  />
                </span>
                <!-- green label -->
                <span *ngIf="false" class="product-content__activity-label-wrap"
                  ><img
                    class="product-content__activity-label"
                    src="/assets/images/icon/product_green_label.svg"
                    alt=""
                  />
                </span>
                <!-- reach label-->
                <span
                  *ngIf="false"
                  class="product-content__activity-label-wrap product-content__activity-label-wrap--last"
                  ><img
                    class="product-content__activity-label"
                    src="/assets/images/icon/product_reach_label.svg"
                    alt=""
                  />
                </span>
              </div>
              <div class="text-section__sn">
                <p>商品編號：</p>
                <p>{{ sn }}</p>
              </div>
              <div class="text-section__expiration" *ngIf="product.expiration">
                <p>商品兌換期：{{ product.expiration }}</p>
              </div>
              <div class="text-section__paymethod">
                <p>可付款方式：</p>
                <p *ngIf="!nopoint">點數兌換</p><p *ngIf="paymethod"><span *ngIf="!nopoint">/</span>電子支付</p>
              </div>
            </div>
            <ng-container
              *ngFor="let item of product.exchangeTypes; let i = index"
            >
            <!-- 只呈現第一種兌換方式 -->
              <ng-container *ngIf="i === 0">
                <div class="text-section__point" *ngIf="item.type == 1">
                  <div class="best-seller__price best-seller__price--point">
                    {{ item.point | number }}點
                  </div>
                </div>
                <div class="text-section__point" *ngIf="item.type == 2">
                  <div class="best-seller__price best-seller__price--point">
                    {{ item.price | number }}元
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <div [hidden]="!limitMsg" class="text-section__point-use-condition">
              <p>商品點數使用條件</p>
              <p>{{ limitMsg }}</p>
            </div>
            <div class="row text-section__add-to-cart-section">
              <div class="col-12 d-flex justify-content-between" [ngClass]="{'in-center':product.isSoldOut}">
                <a
                  class="process-step-button__button process-step-button__button--cancel"
                  (click)="goback()"
                  >回上一頁</a
                >
                <div style="min-width: 20px" [ngStyle]="{'display': product.isSoldOut ? 'none' : ''}"></div>
                <button
                  #addToCartBtn
                  class="text-section__button text-section__button--primary"
                  (click)="addToCart()"
                  mat-raised-button
                  *ngIf="!product.isSoldOut"
                >
                  立刻兌換
                  <!-- <img src="/assets/images/icashpay-icon/shopping-cart.svg" alt=""> -->
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div style="background-color: FFFFFF">
      <article class="ads" *ngIf="product.ads">
        <div class="ads__content" *ngFor="let ad of product.ads">
          <a [href]="ad.cUrl" [target]="ad.cAnother == 1 ? '_blank' : ''">
            <img [src]="ad.cImg" alt="" />
          </a>
        </div>
      </article>
    </div>
    <article class="product-content__note">
      <div class="note__product-spec">
        <h4>商品描述</h4>
        <div *ngIf="product.desc" [innerHTML]="product.desc | html"></div>
      </div>
      <div class="note__warning-list">
        <!-- <h4>商品注意事項</h4> -->
        <div
          *ngIf="product.prodPrecautions"
          [innerHTML]="product.prodPrecautions | html"
        ></div>
      </div>
    </article>
    <div class="recommended-line"></div>
  </section>
</div>

<!-- modal -->
<app-modal
  [isModalOpen]="isModalOpen"
  [isCloseBtnShow]="isCloseBtnShow"
  (modalClose)="isModalOpen = $event; isAgreeRefreshModal = $event"
>
  <h4 headerContent class="general-modal__title">{{ modalTiTle }}</h4>
  <div bodyContent class="general-modal__text-wrap">
    <p class="general-modal__text">{{ modalMsg }}</p>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button
      routerLink="/"
      class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="goHomePage = false"
      *ngIf="goHomePage && !isTicketModalMode"
    >
      返回首頁
    </button>
    <button
      class="general-modal__button general-modal__button--cancel"
      *ngIf="isAgreeRefreshModal"
      (click)="isModalOpen = false; isAgreeRefreshModal = false"
    >
      取消
    </button>
    <button
      class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="onModalConfirm()"
      *ngIf="!goHomePage && !isTicketModalMode"
    >
      確認
    </button>
    <ng-container *ngIf="isTicketProductType && isTicketModalMode">
      <button
        class="general-modal__button general-modal__button--cancel"
        (click)="handleTickeAlertModal()"
      >
        取消
      </button>
      <button
        class="general-modal__button general-modal__button--confirm"
        (click)="handleTickeAlertModal(true)"
      >
        確定
      </button>
    </ng-container>
  </div>
</app-modal>
