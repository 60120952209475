import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/shared/service/http.service';
import { Event } from 'src/app/shared/model/event';
import { BreadcrumbService } from 'src/app/shared/service/breadcrumb.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';
import { HeaderService } from 'src/app/shared/service/header.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  cTypeData = [
    { cType: 21, name: '悠遊付產品與服務' },
    { cType: 13, name: '最新消息' },
    { cType: 12, name: '集點秘笈' }
  ]
  cTypeName: string = '';

  eventInfo: Event = new Event();
  constructor(private httpService: HttpService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private loaderService: LoaderService,
    private pagetagService: PagetagService,
    private location: Location,
    private router: Router,
    public headerService:HeaderService) { }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      let eventId = this.route.snapshot.params['id'];
      this.getEventDetail(eventId);
    });
    this.headerService.Active =false;
  }

  ngAfterViewInit(): void {
    document.addEventListener('click', this.scrollToAnchorTarget);
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.scrollToAnchorTarget);
    this.headerService.Active =true;
  }

  getEventDetail(id: string) {
    this.loaderService.start();
    this.httpService.get("/tbl_ActivityNews/" + id).subscribe(
      (x: any) => {
        this.eventInfo = x;

        this.cTypeData.forEach(item => {
          if (item.cType == this.eventInfo.cType) {
            this.cTypeName = item.name;
          }
        })

        // ---測試用
        // x.cImg = '/assets/images/test/news_detail1.png';
        // x.cContent = '123123123';
        //------

        this.breadcrumbService.breadcrumbData.next([{ breadcrumb: this.cTypeName, path: '/event/' + this.eventInfo.cType }, { breadcrumb: this.eventInfo.cName, path: '' }])
        this.callPagetagService(`${this.cTypeName} | ${this.eventInfo.cName}`);
        this.loaderService.stop();
      })
  }

  backToList($event?: any) {
    if ($event) {
      $event.preventDefault();
    }

    this.router.navigate(['/event', this.eventInfo.cType]);
  }

  scrollToAnchorTarget(e: any) {
    let keyAttribute = 'data-target';

    if (!e.target.getAttribute(keyAttribute)) { return };
    e.preventDefault();

    const target = document.getElementById(e.target.getAttribute('data-target'));
    if (!target) { return };
    target.scrollIntoView();
  }

  callPagetagService(name: string) {
    this.pagetagService.callPageTag(this.router.url, name)
  }

  goback() {
    this.location.back();
  }
}
