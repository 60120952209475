import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MbrInfo } from 'src/app/shared/model/member';
import { BreadcrumbService } from 'src/app/shared/service/breadcrumb.service';
import { CartService } from 'src/app/shared/service/cart.service';
import { HeaderService } from 'src/app/shared/service/header.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';
import { CreateCart } from './../../../shared/model/cart';
import { MobileBrandList } from './../../../shared/model/home';
import {
  ExchangeType,
  Product,
  ProductType,
  RelatedNews,
} from './../../../shared/model/product';
import { HttpService } from './../../../shared/service/http.service';
import { LoginService } from './../../../shared/service/login.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit {
  productID: number;
  Cart: CreateCart;
  Cat1: number;
  Cat2: number;
  product: Product;
  sn: string;
  limitMsg: string;
  paymentMethod: number = 0;
  isInfoShow = false;
  //是否已加入收藏
  isCollect = false;
  // modal
  isModalOpen = false;
  isCloseBtnShow = true;
  goHomePage = false;
  modalTiTle = '';
  modalMsg = '';
  userInfo: MbrInfo;
  goLoginPage = false;
  isAgreeRefreshModal = false;
  relatedNews: RelatedNews;
  paymethod = false;
  nopoint: boolean = false;
  isTicketModalMode = false;
  isTicketProductType = false;

  isMobile = false;
  products: any[] = [];
  mobileBrandList: MobileBrandList = new MobileBrandList();
  constructor(
    private httpService: HttpService,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private cartService: CartService,
    private loaderService: LoaderService,
    private breadcrumbServie: BreadcrumbService,
    private pagetagService: PagetagService,
    private location: Location,
    public headerService: HeaderService
  ) {
    this.product = new Product();
  }

  ngOnInit(): void {
    this.userInfo = this.loginService.GetLocalInfo();
    this.productID = this.route.snapshot.params['id'];
    this.headerService.Footer = true;
    this.isMobile = window.innerWidth > 525 ? false : true;
    this.getProduct();
    this.headerService.Colorless = true;
  }

  ngOnDestroy(): void {
    this.headerService.Colorless = false;
  }
  // 兌換方式UI 更改
  setActivePaymentMethod(num: number, item: ExchangeType) {
    this.isCollect = item.isCollect;
    this.paymentMethod = num;
    this.limitMsg = this.product.exchangeTypes[num].limitPointMsg;
    this.sn = item.sn;
  }

  getProduct() {
    this.loaderService.start();
    const req: Product = new Product();
    req.id = this.productID;
    this.httpService.post('/Product', req).subscribe((x: any) => {
      //+ "?memberId=" + this.userInfo.ID
      if (x.code != 1) {
        this.goHomePage = true;
        this.isCloseBtnShow = false;
        this.modalTiTle = '提示訊息';
        this.modalMsg = x.result;
        this.isModalOpen = true;
      }

      this.product = x.result;
      //檢查付款方式是否有電子支付
      this.paymethod = this.product.exchangeTypes.some(x => x.type === 2);
      if (this.product.exchangeTypes[0].type == 2) {
        this.nopoint = true;
      }
      // this.limitMsg = this.product.exchangeTypes[0].limitPointMsg;
      // this.isCollect = this.product.exchangeTypes[0].isCollect;
      this.sn = this.product.exchangeTypes[0].sn;
      if (this.product.exchangeTypes[1]) {
        this.sn += ',' + this.product.exchangeTypes[1].sn;
      }
      this.relatedNews = this.product.relatedNews;
      this.isInfoShow = !this.relatedNews ? false : true;
      this.isTicketProductType = x.result.productType == ProductType.電子票券;

      // 手機swiper 每兩個為一組
      let products: any[] = [];
      for (let i = 0; i < this.product.recommendProducts.length; i += 2) {
        let item1 = this.product.recommendProducts[i];
        let item2 = this.product.recommendProducts[i + 1];
        if (!item2) {
          products.push([item1]);
        } else {
          products.push([item1, item2]);
        }
      }
      this.product.productLists = products;

      this.breadcrumbServie.breadcrumbData.next([
        {
          breadcrumb: this.product.categoryName1,
          path: '/product/list/' + this.product.category1,
        },
        {
          breadcrumb: this.product.categoryName2,
          path:
            '/product/list/' +
            this.product.category1 +
            '/' +
            this.product.category2,
        },
        { breadcrumb: this.product.name, path: '' },
      ]);

      this.callPagetagService(
        `品牌館 | ${this.product.categoryName1} | ${this.product.categoryName2} | ${this.product.name}`
      );
      this.loaderService.stop();
    });
  }

  addToCart(isAgreeRefresh: boolean = false) {
    this.Cart = new CreateCart();
    this.Cart.Amount = 1;
    this.Cart.MemberId = this.userInfo.Token;
    this.Cart.Category1_id = this.Cat1;
    this.Cart.Category2_id = this.Cat2;
    this.Cart.ProductId = this.product.id;
    this.Cart.ProductDtlId = this.product.exchangeTypes[this.paymentMethod].id;
    this.Cart.isAgreeRefresh = isAgreeRefresh;

    //暫存購物車
    if (sessionStorage.getItem('Cart') != null) {
      sessionStorage.removeItem('Cart');
    }
    sessionStorage.setItem('Cart', JSON.stringify(this.Cart));

    this.router.navigate(['/member', 'cart']);

    //轉購物車API
    // this.httpService.post("/CartNew/CreateCart", this.Cart).subscribe(
    //   (x: any) => {
    //     if (x.code == 0) {
    //       if (x.result == "顯示提醒文案") {
    //         this.isAgreeRefreshModal = true;
    //         x.result = "您欲加入不同商品至購物車，如要繼續，您的購物車將會被清空";
    //       }
    //       this.modalTiTle = "購物車";
    //       this.modalMsg = x.result;
    //       this.isModalOpen = true;
    //       return;
    //     }
    //     else {
    //       this.isAgreeRefreshModal = false;
    //       sessionStorage.setItem("previousPageFromCart", `/product/detail/${this.productID}`);
    //       this.router.navigate(['/member', 'cart'])
    //       // if (x.result == "成功") {
    //       //   // 重新取得購物車數量
    //       //   this.cartService.getCartNumber();
    //       //   // modal
    //       //   this.modalTiTle = "購物車";
    //       //   this.modalMsg = "加入成功";
    //       //   this.isModalOpen = true;
    //       // }
    //       // else if (x.result == "重複") {
    //       //   this.modalTiTle = "購物車";
    //       //   this.modalMsg = "購物車中商品數量已增加";
    //       //   this.isModalOpen = true;
    //       // }
    //     }
    //   });
  }

  openTicketAlertModal() {
    // if (this.userInfo.IsLogin) {
    this.modalTiTle = '購物車';
    this.modalMsg =
      '電子票券僅能兌領一次商品或服務，兌領後即失效。 提醒您，兌領後視同已使用商品或服務，無法提供退換貨。';
    this.isTicketModalMode = true;
    this.isModalOpen = true;
    return;
    // } else {
    //   this.modalTiTle = "購物車";
    //   this.modalMsg = "請先登入會員";
    //   this.isModalOpen = true;
    //   this.goLoginPage = true;
    // }
  }

  handleTickeAlertModal(isAddToCart = false) {
    const _this = this;
    this.isModalOpen = false;

    setTimeout(function () {
      _this.isTicketModalMode = false;
      if (isAddToCart) {
        _this.addToCart();
      }
    }, 200);
  }

  addToFavorite() {
    if (this.isCollect) {
      this.modalTiTle = '收藏';
      this.modalMsg = '已在收藏清單中';
      this.isModalOpen = true;
      return;
    }
    if (this.userInfo.IsLogin) {
      this.Cart = new CreateCart();
      this.Cart.Amount = 1;
      this.Cart.MemberId = this.userInfo.ID;
      this.Cart.Category1_id = this.Cat1;
      this.Cart.Category2_id = this.Cat2;
      this.Cart.ProductDtlId =
        this.product.exchangeTypes[this.paymentMethod].id;

      this.httpService
        .post('/CartNew/CreateColl', this.Cart)
        .subscribe((x: any) => {
          if (x.result == '成功') {
            this.modalTiTle = '收藏';
            this.modalMsg = '收藏成功';
            this.isModalOpen = true;

            this.isCollect = true;
            this.product.exchangeTypes[this.paymentMethod].isCollect = true;
          } else if (x.result == '重複') {
            this.modalTiTle = '收藏';
            this.modalMsg = '無法重複收藏';
            this.isModalOpen = true;
          }
        });
    } else {
      this.modalTiTle = '收藏';
      this.modalMsg = '請先登入會員';
      this.isModalOpen = true;
      this.goLoginPage = true;
    }
  }

  onModalConfirm() {
    this.isModalOpen = false;

    if (this.goLoginPage) {
      let Query = location.pathname.toString() + location.search.toString();
      sessionStorage.setItem('productQuery', Query);
      this.router.navigate(['/login']);
    }
    if (this.isAgreeRefreshModal) {
      this.addToCart(true);
    }
  }

  callPagetagService(name: string) {
    this.pagetagService.callPageTag(this.router.url, name);
  }

  goRecommendProductLink(id: any) {
    id = id.toString();
    this.productID = id;
    window.scrollTo({
      top: 0,
    });
    this.getProduct();
  }
  goback() {
    this.location.back();
  }
}
