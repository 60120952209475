import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { LoginService } from './login.service';
import { Subject } from 'rxjs';
@Injectable()

export class HeaderService {
  //Header顯示
  Global = true;
  //Footer 顯示
  Footer = true;
  Active = true;
  Colorless =false;
  Order = true;
  //Buy 購物車 上面多點數區塊與數量
  Buy = false;
  // 活動抽獎
  lottery = false;
  //首頁(首頁footer沒有置底,所以不需要margin)
  index = false;
  //票券與訂單查詢(不要有footer)
  Ticket = false;
}
