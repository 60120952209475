
export class ActivityReq {
  MbrToken: string;
}
export class ActivityRes {
  activity:activity;
  activitySignIn:activitySignIn;
  activityDraw: activityDraw;
  signInDetail:signInDetail[];
  activityLog:activityLog[];
  awardLog:awardLog[];
  memberInfo:memberInfo;
}

export class DrawRes {
  activity:activity;
  activitySignIn:activitySignIn;
  activityDraw: activityDraw;
  signInDetail:signInDetail[];
  activityLog:activityLog[];
  awardLog:awardLog[];
  memberInfo:memberInfo;
}
export class activityDraw {
  cActivityID:number;
  cAmountGetDraw:number;
  cDailyDraw:number;
  cID:number;
  cImageType:number;
  cPointToDraw:number;
}

export class activityLog {
  cAction: string;
  cCreateDT:Date;
  cIsVisible:boolean;
  cMemberID:number;
  cRemark: string;
  cTraceID:string;
  cType:number;
}

export class awardLog {
  cID:number;
  cActivityID:number;
  cTraceID:string;
  cType:number;
  cAction: string;
  cCreateDT:Date;
  cIsVisible:boolean;
  cMemberID:number;
  cRemark: string;
  cCreator: string;
  cIP: string;
}

export class activity{
  cID:number;
  cName:string;
  cCode:number;
  cSubTitle:string;
  cSDate:Date;
  cEDate:Date;
  cStatus:number;
  cRuleContent:string;
  cType:number;
  cEXStatus:number;
  cEXPassDT:Date;
  cIsDelete:string;
  cCreateDT:Date;
  cCreator:string;
  cUpdateDT:Date;
  cUpdator:string;
}

export class activitySignIn{
  cID:number;
  cActivityID:number;
  cMode:number;
  cSignInDay:number;
}

export class signInDetail{
  cID:number;
  cActivityID:number;
  cDay:number;
  cSignInPoint:number;
}


export class memberInfo{
  cDrawNumber:number;
  cSignDay:number;
  cIsSignIn:boolean;
}

export class ActivityInfo{
  code:number;
  result:result;
}
export class result{
  cAction:string;
  cIsWinner:boolean;
  cType:number;
}
