<section class="point-content">
    <section class="transfer-note-wrap">
        <div class="transfer-note transfer-note--condition">
            <h3 class="transfer-note__title">轉贈點數情境說明</h3>
            <p class="transfer-note__text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis soluta
                iusto
                nihil eos veniam obcaecati
                atque ullam, consequuntur quos. Natus quis qui reiciendis veniam id. Eum vel error amet corrupti?
            </p>
        </div>
        <div class="transfer-note transfer-note--regulation">
            <h3 class="transfer-note__title">轉贈規範描述</h3>
            <p class="transfer-note__text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla,
                perspiciatis
                quaerat laudantium autem
                ipsa beatae placeat? Itaque, facere porro! Doloribus, fugiat doloremque? Laudantium impedit
                voluptatem
                nesciunt dolorum eveniet nobis magni.</p>
        </div>
    </section>
    <section class="current-point-section">
        <div class="current-point-section__title-wrap">
            <h2 class="current-point-section__title">您目前的點數</h2>
        </div>
        <div class="current-point-section__info-wrap current-point-section__info-wrap--platform">
            <p class="current-point-section__info-text current-point-section__info-text--first">悠客券平台</p>
            <p class="current-point-section__info-text  current-point-section__info-text--point">
                <span class="point-dollar-number">
                    <img class="point-dollar-number__image point-dollar-number__image--current-point"
                        src="/assets/images/icon/money-red.svg" alt="">
                    <span class="point-dollar-number__text point-dollar-number__text--primary">{{this.mbrInfo.Bonus | number}}</span>
                </span>點
            </p>
            <button class="current-point-section__button" (click)="openTransferModal()">
                轉贈
            </button>
        </div>
    </section>
</section>

<!-- point transfer modal -->
<app-modal [isModalOpen]="isTransferModalOpen" (modalClose)="isTransferModalOpen=$event">
    <!-- 轉贈點數 / transferModalProcess 1, 2, 3, 4, 5 -->
    <div headerContent class="transfer-process-step">
        <div class="transfer-process-step__item transfer-process-step__item--completed"
            [ngClass]="{'transfer-process-step__item--completed-last': transferModalProcess==1}" data-number="1">
            <span class="transfer-process-step__text">
                輸入轉贈點數
            </span>
        </div>
        <div class="transfer-process-step__item"
            [ngClass]="{'transfer-process-step__item--completed': transferModalProcess>1,'transfer-process-step__item--completed-last':(transferModalProcess==2 || transferModalProcess==3 || transferModalProcess==4)}"
            data-number="2">
            <span class="transfer-process-step__text">
                驗證會員資料
            </span>
        </div>
        <div class="transfer-process-step__item transfer-process-step__item--last"
            [ngClass]="{'transfer-process-step__item--completed': transferModalProcess==5,'transfer-process-step__item--completed-last':transferModalProcess==5}"
            data-number="3">
            <span class="transfer-process-step__text">
                轉贈成功
            </span>
        </div>
    </div>
    <!-- 1 -->
    <div *ngIf="transferModalProcess==1" bodyContent class="general-modal__body">
        <div class="point-transfer-modal__input-wrap">
            <label class="point-transfer-modal__text">請輸入欲轉贈的點數</label>
            <input class="point-transfer-modal__input" type="text">
        </div>
    </div>
    <!-- 2 -->
    <div *ngIf="transferModalProcess==2" bodyContent class="general-modal__body">
        <div class="point-transfer-modal__input-wrap">
            <label class="point-transfer-modal__text">請輸入會員帳號</label>
            <input class="point-transfer-modal__input" type="text">
        </div>
    </div>
    <!-- 3 -->
    <div *ngIf="transferModalProcess==3" bodyContent class="general-modal__body">
        <div class="point-transfer-modal__input-wrap">
            <label class="point-transfer-modal__text">請輸入手機驗證碼</label>
            <input class="point-transfer-modal__input" type="text">
        </div>
        <div class="point-transfer-modal__note-wrap">
            <p class="point-transfer-modal__note">
                <span class="note__icon-wrap">
                    <img class="note__icon" src="/assets/images/icon/shock_mark_white.svg" alt="">
                </span>
                <span>若未收到簡訊認證，您可點選<a href="" class="note__link">重新發送</a>一次</span>
            </p>
        </div>
    </div>
    <!-- 4 -->
    <div *ngIf="transferModalProcess==5" bodyContent class="general-modal__body">
        <div style="margin: auto;">
            <p class="point-transfer-modal__text point-transfer-modal__text--mb10">轉贈成功</p>
        </div>
    </div>
    <!-- 5 -->
    <div *ngIf="transferModalProcess==4" bodyContent class="general-modal__body">
        <div style="margin: auto;">
            <p class="point-transfer-modal__text point-transfer-modal__text--mb15">您確定要轉贈給</p>
            <p class="point-transfer-modal__text point-transfer-modal__text--mb15">王小明</p>
            <p class="point-transfer-modal__text point-transfer-modal__text--mb15">A123456789</p>
        </div>
    </div>
    <!-- 1,2,3,4 footer -->
    <div *ngIf="transferModalProcess!=0 && transferModalProcess!=5" footerContent class="general-modal__button-wrap">
        <button class="general-modal__button general-modal__button--cancel"
            (click)="isTransferModalOpen=false">取消</button>
        <button class="general-modal__button general-modal__button--confirm"
            (click)="transferModalProcess=transferModalProcess+1">確認</button>
    </div>
    <!-- 4 footer -->
    <div *ngIf="transferModalProcess==5" footerContent class="general-modal__button-wrap">
        <button class="general-modal__button general-modal__button--cancel general-modal__button--only"
            (click)="isTransferModalOpen=false">關閉</button>
    </div>
</app-modal>
