import { ActivatedRoute, Router } from '@angular/router';
import { GetTblCodeRes } from './../../../shared/model/home';
import { ContactUsReq } from './../../../shared/model/question';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BreadcrumbService } from 'src/app/shared/service/breadcrumb.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { QuestionService } from 'src/app/shared/service/question.service';
import { Location } from '@angular/common';
import { PagetagService } from 'src/app/shared/service/pagetag.service';

export class ImageSnippet {
  constructor(public base64: any, public file: File) { }
}

@Component({
  selector: 'app-question-contact',
  templateUrl: './question-contact.component.html',
  styleUrls: ['./question-contact.component.scss']
})

export class QuestionContactComponent implements OnInit {
  @ViewChild("imageInput") imageInput: ElementRef;

  isResolved = false;
  images: ImageSnippet[] = [];//上傳圖片

  firstQuestionType: number = 0
  //取得問題類別
  getTblTypeRes = [] as GetTblCodeRes[];
  orderQuestionCodeID?: string;
  //送出表單
  contactUsReq: ContactUsReq = new ContactUsReq();
  //Modal
  isAlertModalOpen: boolean = false;
  alertMsg: string = "";
  isModalOpen: boolean = false;
  modalMsg: string = "";

  //驗證碼
  captchaImage: string;
  captchatoken: string;

  isPrivacyModalOpen = true;

  constructor(private breadcrumbService: BreadcrumbService,
    private questionService: QuestionService,
    private sanitizer: DomSanitizer, //safe
    private httpService: HttpService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private _location: Location,
    private pagetagService: PagetagService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.questionService.questionTypeList.subscribe(x => {
      this.firstQuestionType = x.length > 0 ? x[0].id : 0
    })

    this.breadcrumbService.breadcrumbData.next([
      { breadcrumb: '常見問題', path: this.firstQuestionType.toString() },
      { breadcrumb: '聯絡我們', path: '' }
    ]);
    this.getQuestionType();
    this.callPagetagService();
    this.refresh();
  }
  getQuestionType() {
    this.httpService.get("/Home/GetTblCodeByType?TypeName=聯絡我們問題類別").subscribe((x: any) => {
      this.getTblTypeRes = x;
      this.orderQuestionCodeID = this.getTblTypeRes.find(x => x.cCodeName == "訂單問題")?.cCodeId.toString();
      //若參數帶有訂單編號
      let orderNumber = this.route.snapshot.params['orderNumber'];
      if (orderNumber) {
        this.contactUsReq.CType = this.orderQuestionCodeID ?? "0";
        this.contactUsReq.COrderNumber = orderNumber;
      }
    })
  }
  summitForm() {
    this.loaderService.start();
    //驗證欄位
    var msg = "";
    if (this.contactUsReq.CType == "0") {
      msg += "問題類別,"
    }
    if (this.contactUsReq.CType == "22" && (!this.contactUsReq.COrderNumber.trim())) {
      msg += "訂單編號,"
    }
    if (this.contactUsReq.CSex != 0 && this.contactUsReq.CSex != 1) {
      msg += "性別,"
    }
    if (!this.contactUsReq.CName || this.contactUsReq.CName.trim().length === 0) {
      msg += "姓名,"
    }
    if (!this.contactUsReq.CEmail || this.contactUsReq.CEmail.trim().length === 0) {
      msg += "電子信箱,"
    }
    // if (!this.contactUsReq.CPhone || this.contactUsReq.CPhone.trim().length === 0) {
    //   msg += "聯絡電話,"
    // }
    if (!this.contactUsReq.CTitle || this.contactUsReq.CTitle.trim().length === 0) {
      msg += "主旨,"
    }
    if (!this.contactUsReq.CContent || this.contactUsReq.CContent.trim().length === 0) {
      msg += "您的意見,"
    }

    if (!this.contactUsReq.captchaCode || this.contactUsReq.captchaCode.trim().length === 0) {
      msg += "驗證碼,"
    }

    if (msg != "") {
      msg = msg.substring(0, msg.length - 1);
      msg += " 為必填項目";
      this.alertMsg = msg;
      this.isAlertModalOpen = true;
      this.loaderService.stop();
      return;
    }
    if (!this.isEmail(this.contactUsReq.CEmail)) {
      this.alertMsg = "信箱格式錯誤";
      this.isAlertModalOpen = true;
      this.loaderService.stop();
      return;
    }
    if (this.contactUsReq.CPhone && !/^\d+$/.test(this.contactUsReq.CPhone)) {
      this.alertMsg = "手機格式錯誤";
      this.isAlertModalOpen = true;
      this.loaderService.stop();
      return;
    }
    for (let i = 0; i < 5; i++) {
      var image = "";
      if (this.images[i] && this.images[i].base64) {
        image = this.images[i].base64.changingThisBreaksApplicationSecurity;
      }
      switch (i) {
        case 0: this.contactUsReq.Img1 = image; break;
        case 1: this.contactUsReq.Img2 = image; break;
        case 2: this.contactUsReq.Img3 = image; break;
        case 3: this.contactUsReq.Img4 = image; break;
        case 4: this.contactUsReq.Img5 = image; break;
      }
    }
    this.httpService.post("/tbl_CustomerService", this.contactUsReq).subscribe((x: any) => {
      this.loaderService.stop();
      if (x.code == 0) {
        this.alertMsg = x.result;
        this.isAlertModalOpen = true;
        return;
      } else {
        this.modalMsg = x.result;
        this.isModalOpen = true;
      }
    })
  }
  isPhone(phone: string) {
    let reg = /[0-9]{8}$/;
    return reg.test(phone);
  }

  isEmail(email: string) {
    let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return reg.test(email);
  }
  resolved(e: string) {

    this.contactUsReq.RecaptchaToken = e;
  }


  phoneCheck(e: KeyboardEvent) { //電話號碼限10碼 純數字
    let old = this.contactUsReq.CPhone;
    setTimeout(() => {
      if (
        this.contactUsReq.CPhone &&
        this.contactUsReq.CPhone.length > 10
      ) {
        if (old && old.length > 10) old = old.substring(0, 10);
        this.contactUsReq.CPhone = old;
      }
      if (e.keyCode < 48 || e.keyCode > 57) {
        this.contactUsReq.CPhone = old;
      }
    }, 5);
  }

  processFile(imageInput: any) {
    const limit = 5;
    let file: File[] = imageInput.files;
    //不可重複判斷
    file = Array.from(file).filter(x => {
      let flag = true;
      for (let i = 0; i < this.images.length; i++) {
        if (x.name === this.images[i].file.name) {
          flag = false;
          break;
        }
      }
      if (flag) {
        return x;
      } else {
        return;
      }
    });
    //塞圖base64
    if (file.length && this.images.length < limit) {
      let img;
      let num = (limit - this.images.length);
      num = file.length > num ? num : file.length;
      for (let i = 0; i < num; i++) {
        img = new ImageSnippet(null, file[i]);
        (x => {
          const reader = new FileReader();
          reader.addEventListener('load', (event: any) => {
            x.base64 = this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
          });
          reader.readAsDataURL(x.file);
        })(img);
        this.images.push(img);
      }
    }
  }

  removeImage(img: ImageSnippet) {
    this.imageInput.nativeElement.value = "";
    const i = this.images.indexOf(img);
    this.images.splice(i, 1);
  }

  goLastPage() {
    this._location.back();
  }

  callPagetagService() {
    this.pagetagService.callPageTag(this.router.url, '常見問題 | 聯絡我們')
  }

  handlePrivacyLinkClick(e: any) {
    e.preventDefault();
    this.isPrivacyModalOpen = true;
  }

  refresh() {
    this.httpService.get("/tbl_CustomerService/captcha").subscribe((x: any) => {
      this.captchaImage = x.base64Image;
      this.contactUsReq.RecaptchaToken = this.captchatoken = x.token;
    })
  }
}
