<header class="main-header"
  [ngClass]="{'main-header--fixed': isHeaderFixed,'isSearch': isSearch,'main-header-colorless':headerService.Colorless}"
  *ngIf="headerService.Global || headerService.Colorless">

  <!-- <div class="container homeinput" style="position: relative;" >
    <div class="search content-18">
      <a [routerLink]="'/'"><div class="home"><img src="/assets/images/icon/home_new.svg"></div></a>
      <input type="search" placeholder="請輸入商品關鍵字" [(ngModel)]="keyword">
      <a [routerLink]="'/search/'+keyword"><div><img src="/assets/images/icon/search-icon.svg" class="magnifier"></div></a>
    </div>
  </div> -->


  <div class="container homeinput" style="position: relative;">
    <a [routerLink]="'/'">
      <div class="home"><img src="/assets/images/icashpay-icon/home_icon.svg"></div>
    </a>
    <div class="point content-18" [ngClass]="{'pointleft': isSearch2}">
      <div id="mbr168">會員點數</div>
      <div id="mbr168Point" style="color: #F68B1F">{{this.loginService.mbr168Point | number}}</div>
    </div>
    <div class="search content-18" *ngIf="isSearch2">
      <input type="search" placeholder="請輸入商品關鍵字" [(ngModel)]="keyword">
      <a [routerLink]="'/search/'+keyword">
        <img src="/assets/images/icashpay-icon/search.svg">
      </a>
    </div>

    <div class="magnifier" *ngIf="!isSearch2" (click)="searchChange()"><img
        src="/assets/images/icashpay-icon/search.svg"></div>
    <div class="magnifier" *ngIf="isSearch2" (click)="searchChange()">取消</div>

  </div>

  <div class="main-header__overlay" [ngClass]="{'main-header__overlay--show': isBurgerActived}"></div>
</header>
