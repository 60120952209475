import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/service/header.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  constructor(
    public headerService:HeaderService,) { }

  ngOnInit(): void {
    this.headerService.Footer =false;
    this.headerService.Global =false;
  }

}
