<!-- <app-tab-navbar [pathPrefix]="'/question'" [childrenPathObjList]="childrenPathObjList"></app-tab-navbar> -->
<!-- <h2 class="question-list-title">{{pageTitle}}</h2> -->
<h2 class="question-list-title">常見問題</h2>
<section class="question-list-content">
    <ul class="question-list">
        <li *ngFor="let item of ListRes;let i=index;" class="question-list__item"
            (click)="toggleListContent($event, Q1Content, Q1EditorContent)">
            <a href="#" class="js-question-list__link question-list__link" [attr.data-index]="'Q'">
               <div style="line-height: 26px;"> {{item.cTitle}}</div>
                <span
                    class="js-question-list__item-state-icon--expand question-list__item-state-icon  question-list__item-state-icon--expand"></span>
                <span
                    class="js-question-list__item-state-icon--shrink question-list__item-state-icon question-list__item-state-icon--shrink"></span>
            </a>
            <div #Q1Content class="js-question-list__content question-list__content">
                <div #Q1EditorContent class="js-editor-content-wrap"
                    [innerHTML]="item.cContent | html">
                </div>
            </div>
        </li>
    </ul>
</section>

<div class="process-step-button row">
    <div class="process-step-button__button-wrap">
      <a class="process-step-button__button process-step-button__button--cancel" (click)="goback()">回上一頁</a>
    </div>
  </div>