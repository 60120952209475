import { LoaderService } from './../../../shared/service/loader.service';
import { Cart, DeleteCartReq } from './../../../shared/model/cart';
import { Component, OnInit } from '@angular/core';
import { CreateCart } from 'src/app/shared/model/cart';
import { Product } from 'src/app/shared/model/product';
import { CartService } from 'src/app/shared/service/cart.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoginService } from 'src/app/shared/service/login.service';
import { MbrInfo } from 'src/app/shared/model/member';
import { PagetagService } from 'src/app/shared/service/pagetag.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-member-favorite',
  templateUrl: './member-favorite.component.html',
  styleUrls: ['./member-favorite.component.scss']
})
export class MemberFavoriteComponent implements OnInit {
  isTicketModalMode = false;
  isDeleteModalMode = false;
  isRefreshModalMode = false;
  // modal
  isModalOpen = false;
  modalTiTle = "";
  modalMsg = "";
  deleteCartID: number;
  //加入購物車
  Cart: CreateCart;
  userInfo: MbrInfo;
  infos: Cart[] = [];
  //刪除
  deleteCartReq = new DeleteCartReq();

  //紀錄電子票券商品ID
  tempTicketProductId: number;
  tempRefreshProductId: number;

  constructor(
    private loginService: LoginService,
    private httpService: HttpService,
    private cartService: CartService,
    private loaderService: LoaderService,
    private pagetagService: PagetagService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.userInfo = this.loginService.GetLocalInfo();
    if (this.userInfo.IsLogin) {
      this.getCollectList(this.userInfo.ID);
    }
    this.callPagetagService();
  }
  getCollectList(mbrID: any) {
    this.loaderService.start();

    const qtime = new Date().getTime().toString();
    this.httpService.get("/Member/GetColloectList?str=" + mbrID).subscribe(
      (x: any) => {
        this.infos = x;
        this.loaderService.stop();
      }
    )
  }

  addToCart(productID: number, isAgreeRefresh = false) {
    this.Cart = new CreateCart();
    this.Cart.Amount = 1;
    this.Cart.MemberId = this.userInfo.ID;
    this.Cart.ProductDtlId = productID;
    this.Cart.isAgreeRefresh = isAgreeRefresh;

    this.httpService.post("/CartNew/CreateCart", this.Cart).subscribe(
      (x: any) => {
        if (x.code == 0) {
          if (x.result == "顯示提醒文案") {
            this.isRefreshModalMode = true;
            x.result = "您欲加入不同商品至購物車，如要繼續，您的購物車將會被清空";
          }
          this.tempRefreshProductId = productID;
          this.modalTiTle = "購物車";
          this.modalMsg = x.result;
          this.isModalOpen = true;
          return;
        }
        else {
          this.isRefreshModalMode = false;
          sessionStorage.setItem("previousPageFromCart", "/member/favorite");

          this.router.navigate(['/member', 'cart'])
          // if (x.result == "成功") {
          //   // 重新取得購物車數量
          //   this.cartService.getCartNumber();
          //   // modal
          //   this.modalTiTle = "購物車";
          //   this.modalMsg = "加入成功";
          //   this.isModalOpen = true;
          // }
          // else if (x.result == "重複") {
          //   this.modalTiTle = "購物車";
          //   this.modalMsg = "購物車中商品數量已增加";
          //   this.isModalOpen = true;
          // }
        }
      });
  }

  confirmDelete(cartID: number, $event?: any) {
    if ($event) {
      $event.preventDefault();
    }
    this.isModalOpen = true;
    this.modalTiTle = "收藏";
    this.modalMsg = "確定是否要刪除該收藏商品?";
    this.isDeleteModalMode = true;
    this.deleteCartID = cartID;
  }

  deleteFavorite(cartID: number, fromAddCart: boolean = false) {
    this.isModalOpen = false;
    this.isDeleteModalMode = false;
    this.deleteCartReq.Ids.push(cartID);
    this.deleteCartReq.isAddCollect = false;
    this.httpService.post("/CartNew/Delete", this.deleteCartReq).subscribe(
      (x: any) => {
        var otherInfos = this.infos.filter(x => x.cartID != cartID)
        this.infos = otherInfos;
        if (fromAddCart) {
          return;
        }
        this.isModalOpen = true;
        this.modalTiTle = "收藏";
        this.modalMsg = "刪除成功";
      }
    )
  }

  callPagetagService() {
    this.pagetagService.callPageTag(this.router.url, '會員服務 | 我的收藏')
  }

  openTicketAlertModal(productID: number, e: any) {
    if (e) {
      e.preventDefault();
    }
    this.modalTiTle = "購物車";
    this.modalMsg = "電子票券僅能兌領一次商品或服務，兌領後即失效。 提醒您，兌領後視同已使用商品或服務，無法提供退換貨，亦無法退還點數。";
    this.isTicketModalMode = true;
    this.isModalOpen = true;
    this.tempTicketProductId = productID;
    return;
  }

  handleTickeAlertModal(isAddToCart = false) {
    const _this = this;
    this.isModalOpen = false;

    setTimeout(function () {
      _this.isTicketModalMode = false
      if (isAddToCart) {
        _this.addToCart(_this.tempTicketProductId);
      }
    }, 200);
  }

  handleDeleteModal(isDelete = false) {
    const _this = this;
    this.isModalOpen = false;

    setTimeout(function () {
      _this.isDeleteModalMode = false
      if (isDelete) {
        _this.deleteFavorite(_this.deleteCartID);
      }
    }, 200);
  }

  handleRefreshModal(result = false) {
    const _this = this;
    this.isModalOpen = false;

    setTimeout(function () {
      _this.isRefreshModalMode = false
      if (result) {
        _this.addToCart(_this.tempRefreshProductId, true);
      }
    }, 200);
  }
}
