<h2 class="ticket-title">我的票券</h2>
<section class="ticket-content">
  <section class="filter-section">
    <div class="ticket-type row">
      <div class="ticket-type__name" [ngClass]="{'ticket-type__focus': selectType === 1}" (click)="changeType(1)">序號券
      </div>
      <div class="ticket-type__name" *ngIf="isShowCoupon" [ngClass]="{'ticket-type__focus':  selectType === 2}"
        (click)="changeType(2)">優惠券
      </div>
      <div class="ticket-type__name" *ngIf="isShowGuest" [ngClass]="{'ticket-type__focus':  selectType === 3}"
        (click)="changeType(3)">喜客券
      </div>
    </div>
    <div class="ticket-type-tab">
      <div class="no-scrollbar">
        <div drag-scroll-item class="ticket-type-tab__button-wrap">
          <!-- <button class="ticket-type-tab__button" [ngClass]="{'ticket-type-tab__button--active':true}"
            (click)="changeSortDirection($event)">排序</button> -->

          <button class="ticket-type-tab__button" [ngClass]="{'ticket-type-tab__button--active':true}"
            (click)="sort = true">排序
            <img style="width: 20px; height: 20px;" src="/assets/images/icashpay-icon/sort.svg" alt=""></button>
        </div>
        <div drag-scroll-item class="ticket-type-tab__button-wrap">
          <button class="ticket-type-tab__button" [ngClass]="{'ticket-type-tab__button--active':ticketTypeTab==1}"
            (click)="clickTicketTypeTag(1)">可使用</button>
        </div>
        <div drag-scroll-item class="ticket-type-tab__button-wrap">
          <button class="ticket-type-tab__button" [ngClass]="{'ticket-type-tab__button--active':ticketTypeTab==2}"
            (click)="clickTicketTypeTag(2)">已使用</button>
        </div>
        <div drag-scroll-item class="ticket-type-tab__button-wrap">
          <button class="ticket-type-tab__button" [ngClass]="{'ticket-type-tab__button--active':ticketTypeTab==3}"
            (click)="clickTicketTypeTag(3)">已逾期</button>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- <div class="sort-menu">
      <span class="sort-menu__text">兌換期限</span>
      <button class="sort-menu__desktop-trigger-button js-sort-direction" (click)="changeSortDirection($event)">
        <img class="js-sort-direction" [ngStyle]="{'transform' : !isSortASC? 'rotate(180deg)' : 'none'}"
          src="/assets/images/icon/arrow_top_white.svg" alt="">
      </button>
    </div> -->
  </section>
  <section class="ticket-list">
    <div class="ticket-list__row">
      <!-- 可使用票劵 樣式 -->
      <ng-container *ngIf="filterInfos.length>0">
        <div class="ticket-list__col" *ngFor="let item of filterInfos">
          <div class="ticket-list__item">
            <div class="ticket-list__detail">
              <div class="ticket-list__image-wrap">
                <div class="ticket-list__image-mask" *ngIf="item.ticketType!=1">
                  {{item.ticketType==2?"已使用":(item.ticketType==3?"已過期":"已轉贈")}}</div>
                <img class="ticket-list__image" src="{{item.img}}" alt="">
              </div>
              <div class="ticket-list__info-wrap">
                <!-- <p class="ticket-list__text ticket-list__text--first" *ngIf="item.ticketNo && item.ticketType!=3">
                  票券序號：{{item.ticketNo}}
                </p> -->
                <div class="ticket-list__name">{{item.title}}</div>
                <div style="flex: 1 1 auto;"></div>
                <div class="ticket-list__text"
                  [ngClass]="{'ticket-list__text--first': !(item.ticketNo && item.ticketType!=3)}">
                  兌換期限{{item.useLimitDT | date: 'yyyy/MM/dd'}}
                </div>
                <!-- <p class="ticket-list__text">
                  購買日期：{{item.buyDT}}
                </p> -->
                <!-- <p class="ticket-list__text" *ngIf="item.ticketType!=1">
                  {{item.ticketType==2 ? "使用日期：" : "轉贈日期：" }}{{item.exchangeDT}}
                </p> -->
                <!-- <p class="ticket-list__text">
                  兌換方式：{{item.exchangeType}}
                </p> -->
              </div>
              <div class="ticket-list__button-wrap">
                <!-- ngFor 的時候把item 放進useTicket()裡 -->
                <button class="ticket-list__button" *ngIf="item.ticketType==1" (click)="useTicket(item)">使用</button>
                <button class="ticket-list__button--use" *ngIf="item.ticketType==2">已使用</button>
                <button class="ticket-list__button--use" *ngIf="item.ticketType==3">已逾期</button>
                <!-- <button *ngIf="userInfo.IsEmp" class="ticket-list__button" (click)="shareTicket(item)">分享票券</button>
                <button *ngIf="false" class="ticket-list__button" (click)="transferTicket()">轉贈票券</button> -->
              </div>
            </div>

          </div>
        </div>
      </ng-container>


        <!-- <img class="ticket-empty__image" src="/assets/images/icon/cart_empty.svg" alt=""> -->
        <div class="main-list__noData" *ngIf="filterInfos.length==0">
          <div class="main-list__noData content">
            <div><img src="/assets/images/icashpay-icon/icashpay-icon.svg" alt=""></div>
            <div> {{ticketTypeTab==1?'目前未有可使用的票券資訊':ticketTypeTab==2?'目前未有使用的票券資訊':'目前未有已逾期的票券資訊'}}</div>
          </div>
          <a class="ticket-empty__button" [routerLink]="['/']">去購物吧</a>

        </div>
        <!-- <p class="ticket-empty__text">
          {{ticketTypeTab==1?'目前未有可使用的票券資訊':ticketTypeTab==2?'目前未有使用的票券資訊':'目前未有已逾期的票券資訊'}}</p> -->

    </div>
  </section>
</section>

<div class="bottom-fixed-element" *ngIf="sort" [ngClass]="{'slide-up': sort}">
  <div style="height: 100vh;" (click)="sort = false">
    <div class="sort" [ngClass]="{'slide-up': sort, 'slide-down': !sort}" (click)="$event.stopPropagation();">
      <div class="sort__header">請選擇排序</div>
      <div class="sort__body">
        <div class="cancel-order-modal__reason-section">
          <div class="cancel-order-modal__radio-wrap">
            <label class="cancel-order-modal__label" for="">兌換期限近到遠</label>
            <input name="cancel-order-reason" class="cancel-order-modal__radio" type="radio" value="兌換期限近到遠"
              [(ngModel)]="sortAsc" (ngModelChange)="sortAscChange(sortAsc)">
          </div>
          <div class="cancel-order-modal__radio-wrap">
            <label class="cancel-order-modal__label" for="">兌換期限遠到近</label>
            <input name="cancel-order-reason" class="cancel-order-modal__radio" type="radio" value="兌換期限遠到近"
              [(ngModel)]="sortAsc" (ngModelChange)="sortAscChange(sortAsc)">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal [isModalOpen]="isModalOpen" [noFooter]="noModalFooter" (modalClose)="onModalClose(); isModalOpen = $event">
  <!-- 使用票券 / modalProcess 0 -->
  <h4 *ngIf="modalProcess == 0" headerContent class="general-modal__title">
    {{ modalDataForUseTicket.name }}
  </h4>
  <div *ngIf="modalProcess == 0" bodyContent class="general-modal__body">
    <div class="ticket-use-modal__detail">
      <div class="ticket-use-modal__image-wrap">
        <img class="ticket-use-modal__image" src="{{ modalDataForUseTicket.imgUrl }}" alt="" />
      </div>
      <div class="ticket-use-modal__info-wrap">
        <p class="ticket-use-modal__text">
          票劵效期：{{ modalDataForUseTicket.expiredDate }}
        </p>
        <p class="ticket-use-modal__text">
          票券序號：{{ modalDataForUseTicket.sn }}
        </p>
        <p class="ticket-use-modal__text" *ngIf="modalDataForUseTicket.purchaseDate!=''">
          購買日期：{{ modalDataForUseTicket.purchaseDate }}
        </p>
        <p class="ticket-use-modal__text">
          兌換方式：{{ modalDataForUseTicket.exchangeWay }}
        </p>
      </div>
    </div>
    <div lass="ticket-use-modal__bar-code-wrap">
      <ngx-barcode style="
          max-width: 100%;
          margin-bottom: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        " *ngIf="modalDataForUseTicket.isQR == 0" [bc-value]="modalDataForUseTicket.sn" [bc-display-value]="true">
      </ngx-barcode>
      <ngx-barcode style="
          max-width: 100%;
          margin-bottom: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        " *ngIf="modalDataForUseTicket.isQR == 39" [bc-value]="modalDataForUseTicket.sn" [bc-display-value]="true"
        bc-format="CODE39">
      </ngx-barcode>
      <ngx-qrcode style="
          max-width: 100%;
          margin-bottom: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        " *ngIf="modalDataForUseTicket.isQR == 1" [value]="modalDataForUseTicket.qRcodeString" [width]="300">
      </ngx-qrcode>
      <p *ngIf="modalDataForUseTicket.isQR == 1" style="
          max-width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        ">
        {{ modalDataForUseTicket.couponNo }}
      </p>
      <p *ngIf="modalDataForUseTicket.isQR == 3">
        PIN碼:{{ modalDataForUseTicket.pinCode }}
      </p>
      <ngx-barcode style="
          max-width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        " *ngIf="
          modalDataForUseTicket.isQR == 2 || modalDataForUseTicket.isQR == 3
        " [bc-value]="modalDataForUseTicket.barcode1" [bc-display-value]="true"></ngx-barcode>
      <ngx-barcode style="
          max-width: 100%;
          margin-bottom: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        " *ngIf="modalDataForUseTicket.isQR == 2" [bc-value]="modalDataForUseTicket.barcode2"
        [bc-display-value]="true">
      </ngx-barcode>
      <ngx-barcode style="
          max-width: 100%;
          margin-bottom: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        " *ngIf="modalDataForUseTicket.isQR == 4" [bc-value]="modalDataForUseTicket.multiBarcodeType"
        [bc-display-value]="true">
      </ngx-barcode>
      <ngx-barcode style="
          max-width: 100%;
          margin-bottom: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        " *ngIf="modalDataForUseTicket.isQR == 4" [bc-value]="modalDataForUseTicket.couponNo"
        [bc-display-value]="true">
      </ngx-barcode>
    </div>
    <div class="ticket-use-modal__notice-wrap">
      <h5 class="ticket-use-modal__notice-title">使用電子票券注意事項</h5>
      <div [innerHTML]="modalDataForUseTicket.ticketPrecautions | html"></div>
    </div>
  </div>

  <!-- 轉贈票券 / modalProcess 1 -->
  <!-- <h4 *ngIf="modalProcess == 1" headerContent class="general-modal__title">
    {{ modalDataForTransferTicket.name }}
  </h4>
  <div *ngIf="modalProcess == 1" bodyContent class="general-modal__body">
    <div class="ticket-use-modal__detail">
      <div class="ticket-use-modal__image-wrap">
        <img class="ticket-use-modal__image" src="{{ modalDataForTransferTicket.imgUrl }}" alt="" />
      </div>
      <div class="ticket-use-modal__info-wrap">
        <p class="ticket-use-modal__text">
          票劵到期日：{{ modalDataForTransferTicket.expiredDate }}
        </p>
        <p class="ticket-use-modal__text">
          分享方式
          <a href="https://www.facebook.com/sharer/sharer.php?u={{
              transferLink
            }}"><img class="share-icon" src="/assets/images/icon/fb-icon.svg" />
          </a>
          <a href="https://social-plugins.line.me/lineit/share?url={{
              transferLink
            }}"><img class="share-icon" src="/assets/images/icon/line-icon.svg" />
          </a>
          <a href="script:void(0)" (click)="callServiceToCopy(transferLink); Opencopy(2)"><img class="share-icon"
              src="/assets/images/icon/link-icon.svg" />
          </a>
        </p>
        <div class="copysucc" *ngIf="copy == 2">
          <a>已複製成功！</a>
        </div>
      </div>
    </div>
  </div> -->

  <!-- 1,2,3,4 footer -->
  <div *ngIf="modalProcess != 0 && modalProcess != 5" footerContent class="general-modal__button-wrap">
    <!-- <button
      class="general-modal__button general-modal__button--cancel"
      (click)="isModalOpen = false"
    >
      取消
    </button>
    <button
      class="general-modal__button general-modal__button--confirm"
      (click)="modalProcess = modalProcess + 1"
    >
      確認
    </button> -->
  </div>
  <!-- 5 footer -->
  <div *ngIf="modalProcess == 5" footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--cancel general-modal__button--only"
      (click)="isModalOpen = false">
      關閉
    </button>
  </div>
  <!-- 6 分享票券 -->
  <!-- <h4 *ngIf="modalProcess == 6" headerContent class="general-modal__title">
    {{ modalDataForShareTicket.name }}
  </h4> -->
  <!-- <div *ngIf="modalProcess == 6" bodyContent class="general-modal__body">
    <div class="ticket-use-modal__detail">
      <div class="ticket-use-modal__info-wrap">
        <p class="ticket-use-modal__text">
          哈囉，這是我在愛金卡兌換平台兌換的票券，分享給你哦
        </p>
        <p class="ticket-use-modal__text">
          分享連結的到期日:
          {{ modalDataForTransferTicket.expiredDate | date : "yyyy/MM/dd" }}
        </p>
        <p class="ticket-use-modal__text">要趕快點選連結完成登入，領取哦。</p>

        <a href="javascript:void(0)" (click)="callServiceToCopy(transferLink); Opencopy(2)"><img class="share-icon"
            src="/assets/images/icon/link-icon.svg" />
        </a>
        <div class="bearpop">
          <img src="/assets/images/logo/熊1.png" />
        </div>
        <div class="copysucc" *ngIf="copy == 2">
          <a>已複製成功！</a>
        </div>
      </div>
    </div>
  </div> -->
</app-modal>

<div class="process-step-button row">
  <div class="process-step-button__button-wrap">
    <a class="process-step-button__button process-step-button__button--cancel" (click)="goback()">回上一頁</a>
  </div>
</div>
