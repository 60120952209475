import { Component, OnInit } from '@angular/core';
import { QuestionService } from 'src/app/shared/service/question.service';
import { HeaderService } from 'src/app/shared/service/header.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  constructor(private questionService: QuestionService,
    public headerService:HeaderService) { }

  ngOnInit(): void {
    this.headerService.Footer = true;
  }
  ngOnDestroy() {
    this.headerService.Footer = false;
  }

}
