import {  OnInit, ViewChild } from '@angular/core';
import { HomeBanner } from '../../model/home';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SwiperComponent } from "swiper/angular";
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-owl-carousel',
  templateUrl: './owl-carousel.component.html',
  styleUrls: ['./owl-carousel.component.scss']
})
export class OwlCarouselComponent implements OnInit,OnDestroy {
  @ViewChild('owlCarousel') owlCarousel: any;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 3000,
    navText: ['', ''],
    merge: true,
    margin: 8,
    autoWidth:true,
    autoHeight: false,
    center:true,
    responsive: {
      //在特定寬度下顯示幾張圖
      0: {
        items: 1.13,
      },

    },
  };


  isMobile = false;
  @Input() isTop = false;

  isinit = false;
  carouselAutoCounter: number = 0; // for timeout
  carouselItemActive: number = 0; // for UI
  carouselInterval: ReturnType<typeof setTimeout>;

  // 父元件給定作為錨點的元素
  @Input('anchorElement') public parentAnchorElement: HTMLElement;
  @Input('banners') public banners: HomeBanner[];
owlOptions: any;
OwlOptions: any;

  // banners: HomeBanner[];

  constructor(
    private elements: ElementRef,
    public sanitizer: DomSanitizer //safe
  ) {}

  ngOnInit(): void {
    if (window.innerWidth < 576) {
      this.isMobile = true;
    }

    this.setCarouselInteval();
    this.resetCarouselHeight();

    if(this.banners && this.banners.length == 1 ){
      this.customOptions.loop =false;
    }
  }

  getUrl() {
    this.isinit = true;
    this.banners.forEach((i, index) => {
      if (i.cMediaType == 2) {

        let id = 'iframe:' + index;
        let iframe = document.getElementById(id);
        if (iframe) {
          iframe.setAttribute(
            'src',
            'https://www.youtube.com/embed/' + i.cImg + '?autoplay=1&mute=1'
          );
        }
      }
      // i.youtubeUrl = this.getSafeUrl(i.cImg);
    });
  }
  ngAfterViewInit(): void {
        if (!this.isinit) this.getUrl();
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
  }
  ngOnDestroy() {
    this.clearCarouselInterval();
  }

  @HostListener('window:resize', ['$event'])
  onResize($event?: any) {
    if (window.innerWidth < 576) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    // 待確認圖片上傳的固定高度
    this.resetCarouselHeight();
  }

  onCarouselChange(element: HTMLElement, index: number, $event?: any) {
    if ($event) {
      $event.preventDefault();
    }

    const marginDistance = '-' + index + '00%';
    if(element)element.style['marginLeft'] = marginDistance;

    this.carouselAutoCounter = index;
    this.carouselItemActive = index;

    this.resetCarouselHeight();
    // 重新計算timout 時間
    this.clearCarouselInterval();
    this.setCarouselInteval();
  }

  resetCarouselHeight(imageElement?: HTMLImageElement) {
    if (this.banners.length == 0) {
      return;
    }
    if (imageElement && !imageElement.classList.contains('js-active-image')) {
      return;
    }

    // 更換圖片後調整最外層容器高，避免遮色片位置不對 // 寫法待優化
    // const _this = this;
    // setTimeout(function () {
    //   const carousel =
    //     _this.elements.nativeElement.querySelector('.js-carousel');
    //   const carouselActiveItemImage =
    //     _this.elements.nativeElement.querySelector('.js-active-image');

    //   if (carouselActiveItemImage)
    //     carousel.style['height'] = carouselActiveItemImage.offsetHeight + 'px';
    //   else {
    //     carousel.style['height'] = '340px';
    //   }
    // }, 0);
  }

  setCarouselInteval() {
    const _this = this;

    this.carouselInterval = setInterval(function () {
      // if (_this.carouselAutoCounter == (_this.carouselItemAmount - 1)) {
      if (_this.carouselAutoCounter == _this.banners.length - 1) {
        _this.carouselAutoCounter = 0;
      } else {
        _this.carouselAutoCounter++;
      }
      const index = _this.carouselAutoCounter;
      const element =
        _this.elements.nativeElement.querySelector('.js-carousel-inner');
      _this.onCarouselChange(element, index);
    }, 5000);
  }

  clearCarouselInterval() {
    clearInterval(this.carouselInterval);
  }

  getSafeUrl(url: string) {
    let index = url.indexOf('&list=');
    let temp = url;
    if (index > -1) temp = temp.substring(0, index);
    temp = temp.replace('/watch?v=', '/embed/') + '?autoplay=1&mute=1';
    return temp;
    // return this.sanitizer.bypassSecurityTrustResourceUrl(temp);
  }
  scrollToAnchor($event: any) {
    $event.preventDefault();

    // 避免父元件未給定target element，預設距離為0
    let elementScrollTop = 0;
    if (this.parentAnchorElement) {
      elementScrollTop =
        this.parentAnchorElement.offsetTop -
        this.parentAnchorElement.scrollTop +
        this.parentAnchorElement.clientTop;
    }

    if (window.innerWidth < 1200) {
      // 因header 手機版有浮動區塊，需多給距離以免被遮擋
      elementScrollTop -= 50;
    }

    window.scrollTo({
      top: elementScrollTop,
      behavior: 'smooth',
    });
  }
}
