import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { MbrInfo, Ticket } from 'src/app/shared/model/member';
import { HeaderService } from 'src/app/shared/service/header.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-member-ticket-detail',
  templateUrl: './member-ticket-detail.component.html',
  styleUrls: ['./member-ticket-detail.component.scss']
})
export class MemberTicketDetailComponent implements OnInit {
  prodPrecautions: string = '';
  ticket: Ticket;
  isPointModalOpen = false;
  isModalOpenByCopy = false;
  userInfo: MbrInfo;

  constructor(
    private httpService: HttpService,
    public headerService: HeaderService,
    private clipboard: Clipboard,
    private loaderService: LoaderService,
    private loginService: LoginService,
    private reuter: Router
    ) { }

  ngOnInit(): void {
    // this.headerService.Global = false;
    this.headerService.Order = false;
    // this.headerService.Footer = true;
    this.userInfo = this.loginService.GetLocalInfo();

    this.prodPrecautions = '票券注意事項';
    const ticket = localStorage.getItem('ticket');
    this.ticket = ticket ? JSON.parse(ticket) : new Ticket();

  }

  ngOnDestroy(): void {
    this.headerService.Order = true;

  }

  goTicket() {
    this.reuter.navigate(['member/ticket']);
  }

  send() {
    this.exchangeVoucher(this.userInfo.Token, this.ticket.couponNo);
  }

  copyLink() {
    this.clipboard.copy(this.ticket.ticketNo);
    this.isModalOpenByCopy = true;
  }

  // 使用票券
  exchangeVoucher(mbrID: string, couponNo: string) {
    this.loaderService.start();
    this.httpService
      .get('/Member/ExchangeVoucher?mbrToken=' + mbrID + '&couponNo=' + couponNo)
      .subscribe((errMessage: any) => {
        this.isPointModalOpen = false;
        this.loaderService.stop();
        if(!errMessage) {
          this.goTicket();
        } else {
          // show error
        }

      });
  }
}
