<div class="activity container">
    <section class="activity-content">
        <section class="filter-section">
            <div class="activity-type row">
                <div class="activity-type__name" [ngClass]="{'activity-type__focus': selectType === 1}"
                    (click)="changeType(1)">
                    <div [ngClass]="{'activity-type__line':  selectType === 1}"></div>
                    獲獎紀錄
                </div>
                <div class="activity-type__name" [ngClass]="{'activity-type__focus':  selectType === 2}"
                    (click)="changeType(2)" *ngIf="lottery">活動紀錄
                    <div [ngClass]="{'activity-type__line':  selectType === 2}"></div>
                </div>
            </div>
        </section>
        <section class="activity-list">
            <div class="activity-list__row">
              <ng-container *ngIf="selectType === 1 && !lottery">
                <div class="activity-list__col" *ngFor="let data of ActivityRes.activityLog">
                    <div class="activity-list__item">
                        <div class="activity-list__detail">
                            <!-- <div class="activity-list__image-wrap">
                                <div class="activity-list__image-mask">xx</div>
                            </div> -->
                            <div class="activity-list__info-wrap">
                                <div class="activity-list__name">{{data.cAction}}</div>
                                <div style="flex: 1 1 auto;"></div>
                                <div class="activity-list__text">
                                    {{data.cCreateDT| date: 'yyyy/MM/dd HH:mm'}}
                                </div>
                            </div>
                            <div class="activity-list__button-wrap">
                                <div class="activity-list__point">{{data.cRemark}}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectType === 1 && lottery">
              <div class="activity-list__col" *ngFor="let data of ActivityRes.awardLog">
                  <div class="activity-list__item">
                      <div class="activity-list__detail">
                          <!-- <div class="activity-list__image-wrap">
                              <div class="activity-list__image-mask">xx</div>
                          </div> -->
                          <div class="activity-list__info-wrap">
                              <div class="activity-list__name">{{data.cAction}}</div>
                              <div style="flex: 1 1 auto;"></div>
                              <div class="activity-list__text">
                                  {{data.cCreateDT| date: 'yyyy/MM/dd HH:mm'}}
                              </div>
                          </div>
                          <div class="activity-list__button-wrap">
                              <div class="activity-list__point">{{data.cRemark}}</div>
                          </div>
                      </div>

                  </div>
              </div>
          </ng-container>
                <ng-container *ngIf="selectType === 2">
                  <div class="activity-list__col" *ngFor="let data of ActivityRes.activityLog">
                      <div class="activity-list__item">
                          <div class="activity-list__detail">
                              <div class="activity-list__image-wrap">
                                  <div class="activity-list__image-mask">xx</div>
                              </div>
                              <div class="activity-list__info-wrap">
                                  <div class="activity-list__name">{{data.cAction}}</div>
                                  <div style="flex: 1 1 auto;"></div>
                                  <div class="activity-list__text">
                                      {{data.cCreateDT| date: 'yyyy/MM/dd HH:mm'}}
                                  </div>
                              </div>
                              <div class="activity-list__button-wrap">
                                  <div class="activity-list__point">{{data.cType == 2 ? "-1" : data.cRemark}}</div>
                              </div>
                          </div>

                      </div>
                  </div>
              </ng-container>
                <!-- <div class="main-list__noData">
                    <div class="main-list__noData content">
                        <div><img src="/assets/images/icashpay-icon/icashpay-icon.svg" alt=""></div>
                        <div> {{ticketTypeTab==1?'目前未有可使用的票券資訊':ticketTypeTab==2?'目前未有使用的票券資訊':'目前未有已逾期的票券資訊'}}</div>
                    </div>
                </div> -->
            </div>

            <div class="process-step-button row">
                <div class="process-step-button__button-wrap">
                    <a class="process-step-button__button process-step-button__button--cancel" (click)="goback()">回上一頁</a>
                </div>
            </div>
        </section>
    </section>
</div>
