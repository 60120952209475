<h2 class="contact-title">聯絡我們</h2>
<p class="contact-note">請填寫表單</p>
<p class="contact-note contact-note--last">我們將於收到您的來信後2個工作日內回覆您，感謝您的耐心等候！</p>
<a class="privacy-link" href="#" (click)="handlePrivacyLinkClick($event)">網站隱私權保護聲明</a>
<section class="contact-content">
  <div class="contact-form">
    <div class="contact-form__input-group">
      <label for="" class="contact-form__label">問題類別<span>＊</span></label>
      <select name="" id="" class="contact-form__select" [(ngModel)]="contactUsReq.CType">
        <option value="0" disabled selected>請選擇</option>
        <option value="{{item.cCodeId}}" *ngFor="let item of getTblTypeRes">{{item.cCodeName}}</option>
      </select>
    </div>
    <div class="contact-form__input-group" [hidden]="contactUsReq.CType!=orderQuestionCodeID">
      <label for="" class="contact-form__label">訂單編號<span>＊</span></label>
      <input type="text" class="contact-form__input" placeholder="請輸入訂單編號資訊" [(ngModel)]="contactUsReq.COrderNumber">
    </div>
    <div class="contact-form__input-group">
      <label for="" class="contact-form__label">性別<span>＊</span></label>
      <div class="contact-form__radio-wrap">
        <input type="radio" name="sex" class="contact-form__radio" (click)="contactUsReq.CSex=0">
        <label for="" class="contact-form__radio-label">男</label>
      </div>
      <div class="contact-form__radio-wrap">
        <input type="radio" name="sex" class="contact-form__radio" (click)="contactUsReq.CSex=1">
        <label for="" class="contact-form__radio-label">女</label>
      </div>
    </div>
    <div class="contact-form__input-group">
      <label for="" class="contact-form__label">姓名<span>＊</span></label>
      <input type="text" class="contact-form__input" placeholder="請填寫完整姓名，勿使用暱稱" [(ngModel)]="contactUsReq.CName">
    </div>
    <div class="contact-form__input-group">
      <label for="" class="contact-form__label">電子信箱<span>＊</span></label>
      <input type="text" class="contact-form__input" placeholder="請輸入正確的e-mail" [(ngModel)]="contactUsReq.CEmail">
    </div>
    <div class="contact-form__input-group">
      <label for="" class="contact-form__label">聯絡電話</label>
      <input type="text" class="contact-form__input" placeholder="請輸入聯繫電話資訊"  maxlength="10" (keypress)="phoneCheck($event)" [(ngModel)]="contactUsReq.CPhone">
    </div>
    <div class="contact-form__input-group">
      <label for="" class="contact-form__label">主旨<span>*</span></label>
      <input type="text" class="contact-form__input" placeholder="請輸入" [(ngModel)]="contactUsReq.CTitle">
    </div>
    <div class="contact-form__input-group">
      <label for="" class="contact-form__label">您的意見<span>＊</span></label>
      <textarea class="contact-form__textarea" name="" id="" placeholder="提醒您，如果是登入相關問題，請您務必留下您的登入帳號資訊，以利問題的確認，謝謝。"
        maxlength="500" cols="30" rows="10" [(ngModel)]="contactUsReq.CContent"></textarea>
    </div>
    <div class="contact-form__image-input-wrap">
      <label for="image-upload" class="contact-form__image-label">
        圖片上傳
      </label>
      <input class="contact-form__image-input" #imageInput id="image-upload" type="file" accept="image/*"
        (change)="processFile(imageInput)" />
      <span for="" class="contact-form__label contact-form__label--image">圖片上限5張</span>
    </div>
    <div class="contact-form__image-wrap" *ngIf="images.length">
      <div class="contact-form__image-item" *ngFor="let item of images">
        <button class="contact-form__image-delete-btn" (click)="removeImage(item)">
          <span>－</span>
        </button>
        <img class="contact-form__image" *ngIf="item.base64" [src]="item.base64" />
      </div>
    </div>

    <!-- <div class="contact-form__recaptcha-wrap">
      <re-captcha id="recaptcha" required (resolved)="resolved($event);isResolved=true">
      </re-captcha>
    </div> -->

    <div class="contact-form__input-group">
      <label for="" class="contact-form__label">驗證碼<span>＊</span></label>
      <div style="width: 100%;">
        <input class="newinput" matInput placeholder="請輸入驗證碼" [(ngModel)]="contactUsReq.captchaCode"
          autocomplete="false">
        <img [src]="captchaImage" style="width: 100px;margin: 0 8px;">
        <img src="/assets/images/icon/refresh.svg" style="width: 22px;height: 22px;" (click)="refresh()" class="refresh">
      </div>
    </div>

    <div class="contact-form__button-wrap">
      <a (click)="goLastPage()" class="contact-form__button contact-form__button--cancel">取消</a>
      <button class="contact-form__button contact-form__button--confirm" (click)="summitForm()">送出</button>
    </div>
  </div>
</section>

<div class="process-step-button row">
  <div class="process-step-button__button-wrap">
    <a (click)="goLastPage()" class="process-step-button__button process-step-button__button--cancel">回上一頁</a>
    <!-- <button class="process-step-button__button process-step-button__button--next" (click)="summitForm()">
      送出
    </button> -->
  </div>
</div>


<!-- alertModal -->
<app-modal [isModalOpen]="isAlertModalOpen" (modalClose)="isAlertModalOpen=$event">
  <h4 headerContent class="general-modal__title">提示信息</h4>
  <div bodyContent class="general-modal__text-wrap">
    <p class="general-modal__text" *ngFor="let row of alertMsg.split('br')">{{row}}</p>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="isAlertModalOpen=false">確認</button>
  </div>
</app-modal>

<!-- modal -->
<app-modal [isModalOpen]="isModalOpen" (modalClose)="isModalOpen=$event">
  <h3 headerContent class="general-modal__title">系統通知</h3>
  <div bodyContent class="general-modal__text-wrap">
    <p class="general-modal__text">{{modalMsg}}</p>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--confirm" [routerLink]="['/']">確認</button>
  </div>
</app-modal>

<app-modal [isModalOpen]="isPrivacyModalOpen" (modalClose)="isPrivacyModalOpen=$event">
  <h3 headerContent class="general-modal__title__privacy">隱私權保護政策</h3>
  <div bodyContent class="privacy-modal__content-wrap">
    <p class="privacy-modal__text CopyRightText textfullpage">喜客券商城(以下稱本平台)使用者個人資料暨隱私權保護政策，請參閱以下條款：</p>
    <ol class="privacy-modal__number-list">
      <li class="privacy-modal__number-list-item">
        <!-- <span class="privacy-modal__number-list-title">1.本聲明適用之範圍</span> -->
        <span class="privacy-modal__number-list-text textfullpage">
          1.本聲明適用於icash Pay會員藉由電腦或行動裝置連結登入喜客券商城時，即適用本聲明。本聲明不適用於本平台以外之相關網站連結，倘您點選該連結，您必須參考該連結網站中的隱私權保護政策。。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">2.個人資料之蒐集聲明</span>
        <span class="privacy-modal__number-list-text textfullpage">
          (1)喜客券商城係由「精誠資訊股份有限公司」(以下稱精誠資訊)所維運。本平台向您聲明，當您以icash Pay會員身分登入喜客券商城時，本平台之icash Pay會員身分驗證係由愛金卡股份有限公司之icash Pay產品協助驗證，本平台並不保留身分證字號等信息。。
          <br />
          (2)您如參與商品兌換活動或贈獎活動，本平台可能會請您提供收件人姓名、住址、電話、e-mail或其他相關資料，俾便寄送活動兌換之商品或贈送之獎品予您。
          <br>
          (3)當您透過本平台進行交易，為辦理收付款金流服務時，本平台將導至愛金卡股份有限公司所提供之icash Pay支付方式，您將因進行交易而使用該支付服務，其所提供的相關資料之保護及管理，請參閱該交易服務之隱私權政策。
          <br>
          (4)除此之外，我們也會保留您在上網瀏覽或查詢時，伺服器自行產生的相關紀錄，包括但不限於您使用連線設備的IP位址、使用的瀏覽器、使用時間、瀏覽及點選紀錄等。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">3.蒐集資料之運用聲明</span>
        <span class="privacy-modal__number-list-text textfullpage">
          (1)您於線上商品兌換服務中所提供的個人資訊將會由負責該商品服務的相關單位處理，以便與您聯繫。
          <br>
          (2)各類線上商品兌換或獎品贈送，您所提供的個人資料，將在取得您明確同意的前提下，視蒐集目的分別做為答覆問題、客服通知，票券提供、或是商業及市場分析之用。個人資料之使用目的、利用對象、時間等資訊請參考本平台之內容，從本平台上獲得的資料將只使用在與資料蒐集時所告知您的特定目的上。除非在蒐集時有向您告知並徵得您的同意，否則本平台所蒐集的個人資料，將不會與他人共同使用、亦不會使用在未告知您的用途上。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">4.Cookie 技術之使用</span>
        <span class="privacy-modal__number-list-text textfullpage">
          (1)本平台可能使用cookie技術，為了提供便利使用者提供更適合會員個人需要的服務；cookie是本平台伺服器用來和使用者瀏覽器進行溝通的一種技術，它可能在使用者的電腦或行動裝置中儲存某些資訊，本平台也會讀取儲存在使用者電腦中的cookie資料。您可以經由電腦或行動裝置中瀏覽器的設定，取消、或限制此項功能，但可能因此無法使用部份網站功能。若您想知道如何取消、或限制此項功能，可參照您所使用的瀏覽器關於如何管理cookie的相關說明。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span
          class="privacy-modal__number-list-title textfullpage">5.本平台特定目的外利用個資之聲明：本平台絕不會洩露或轉售您的個人資料給其他與您所參與活動不相關的第三方。惟有下列三種情形，本平台會於特定目的外利用您的個人資料：</span>
        <span class="privacy-modal__number-list-text">
          <!-- 本平台絕不會洩露或轉售您的個人資料給其他與您所參與活動不相關的第三方。惟有下列三種情形，本平台會與第三方共用您的個人資料： -->
          <ul class="privacy-modal__bullet-list">
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              取得您的明確同意或授權允許時。
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              司法單位或其他政府機關經合法正式的程序要求時。
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              為了提供您其他服務或優惠權益，需要與提供該服務或優惠之第三人於特定目的外利用您的資料時，本平台會在活動時提供充分說明並告知，您可以自由選擇是否接受這項服務或優惠。
            </li>
          </ul>
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span
          class="privacy-modal__number-list-title textfullpage">6.您將可能在本平台中點選到第三方網站，該等第三方網站的隱私權保護聲明運作，並非由本平台管理，所以於該第三方網站中，不適用本平台隱私權保護聲明。建議您在該等第三方網站中欲提供個人資料前，詳閱該等第三方網站的隱私權保護相關聲明。</span>
        <!-- <span class="privacy-modal__number-list-text">
          在本平台中，您將可能點選到第三方網站。該等第三方網站的隱私權保護聲明運作，並非由本平台管理，所以於該等第三方網站中，不適用本平台隱私權保護聲明。建議您在該等第三方網站中欲提供個人資料前，詳閱該等第三方網站的隱私權保護相關聲明。
        </span> -->
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title textfullpage">7.
          當事人權利行使：除法令另有規定外，您得對本平台所蒐集、處理、利用與保存您的個人資料，要求：</span>
        <span class="privacy-modal__number-list-text">
          <!-- 除法令另有規定外，您得對本平台所蒐集、處理、利用與保存您的個人資料，要求： -->
          <ul class="privacy-modal__bullet-list">
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              撤回先前有關蒐集、處理或利用之同意；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              限制處理或利用方式(限歐盟居民及歐盟居民自歐盟境內所提供之個人資料適用)；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              查詢或閱覽；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              製給複製本；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              將您所提供之個人資料移轉到您所指定的第三方或裝置(限歐盟居民及歐盟居民自歐盟境內所提供之個人資料適用)；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              補充或更正；
            </li>
            <li class="privacy-modal__bullet-list-item">
              <span class="privacy-modal__bullet"></span>
              停止蒐集、處理或利用；或刪除。
            </li>
          </ul>
        </span>
      </li>
      <!-- <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-text">
          本平台將依個人資料保護法及相關法令規範，決定是否受理您的申請。<br />
          除法令另有規定外，本平台將於蒐集之特定目的達成或消滅後，依本平台內部規定將您的個人資料銷毀刪除，或將您的個人資料去識別化且後續無法追蹤到您本人的方式留存。
        </span>
      </li> -->
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">8.安全性</span>
        <span class="privacy-modal__number-list-text textfullpage">
          為了防止您所提供的個人資料遭外洩、遺失、濫用、變更或破壞，本平台將使用通常認可的標準技術以及操作上的防護，來保護您的個人資料，例如網站的傳輸過程使用SSL（Secure Socket Layer）作為資料傳輸加密保護機制
          。但由於本平台無法確保不會發生未經授權的存取事件，我們建議您不要將您使用本平台的相關帳號密碼分享給任何第三人；如您與他人共用電腦或行動裝置，你應充分注意於離開本平台前先行登出您的帳號，以防止後續使用者得接觸到您於本平台所留存的個人資訊。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">9.本聲明之修訂</span>
        <span class="privacy-modal__number-list-text textfullpage">
          由於社會環境及法令規定之變遷或科技技術進步，本平台將盡全力保護您的隱私，故本平台有權不定時修訂與公布本項聲明以合時宜。也請您隨時上網參閱本項聲明，以保障您的權益。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        <span class="privacy-modal__number-list-title">10.會員聯絡資訊</span>
        <span class="privacy-modal__number-list-text textfullpage">
          本平台為保護使用者個人資料，維護網路隱私權，特訂定本隱私權保護聲明，若您對於本隱私權保護聲明、或與個人資料有關之相關事項有任何疑問，或欲行使上述當事人權利時，可利用本平台”聯絡我們”
          詢問，我們會提供最完整的說明。如您認為本平台有違反法令致影響您的權益時，您可與本平台聯繫或有權利向個資保護相關主管機關進行申訴。
        </span>
      </li>
      <li class="privacy-modal__number-list-item">
        本隱私權聲明最近更新：113年 05月 06日
      </li>
    </ol>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--confirm"
      (click)="isPrivacyModalOpen = false;">確認</button>
  </div>
</app-modal>
