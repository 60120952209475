import { Injectable } from '@angular/core';
import { ChildActivationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MbrInfo } from '../model/member';
import { HttpService } from './http.service';
import { AES, mode, pad, enc } from 'crypto-js';
@Injectable()
export class LoginService {
  localKey = 'mbrkey';
  expireMin: number = environment.expireMin;
  encryptKey: string = environment.encryptKey;
  mbr168Point: number;
  $token = new BehaviorSubject<string>('');
  private userInfoSource = new BehaviorSubject<MbrInfo>(this.GetLocalInfo());
  userInfo = this.userInfoSource.asObservable();
  constructor(private http: HttpService, private router: Router) {}

  Login(token: string): Observable<string> {
    return new Observable((subscriber) => {
      this.http
        .post('/Member/CheckMember', { token: token })
        .subscribe((x: any) => {
          if (x.code == 1) {
            let mbrInfo = new MbrInfo();
            mbrInfo.Token = x.result;
            sessionStorage.setItem('jwtToken', x.result);
            console.log(mbrInfo);
            this.SetLocalInfo(mbrInfo);
            subscriber.next('success');
            subscriber.complete();
          } else {
            this.ClearLocalInfo();
            subscriber.next(x.result);
            subscriber.complete();
          }
        });
    });
  }

  Logout() {
    this.ClearLocalInfo();
    location.href = '/';
  }
  GetLocalInfo() {
    let model = new MbrInfo();
    let info = sessionStorage.getItem(this.localKey) ?? '';
    if (info != '') {
      let decrypt = this.encryptByDeAES(info);
      const m = JSON.parse(decrypt);
      m.Bonus = m.Bonus? parseInt(m.Bonus) : 0;
      model = m;
    }
    return model;
  }
  SetLocalInfo(info: MbrInfo) {
    sessionStorage.setItem(
      this.localKey,
      this.encryptByEnAES(JSON.stringify(info))
    );
    this.userInfoSource.next(info);
  }
  ClearLocalInfo() {
    sessionStorage.removeItem(this.localKey);
    // sessionStorage.removeItem(this.localKey);
    this.ClearCookie();
  }
  ResetBonus(): Observable<string> {
    let userInfo = this.GetLocalInfo();
    console.log(userInfo);
    return new Observable((subscriber) => {
      this.http
        .post('/iCashPay/QueryPoint', { icp: userInfo.Token })
        .subscribe((x: any) => {
          var info = this.GetLocalInfo();
          info.Bonus = x.result ? parseInt(x.result) : 0;
          this.mbr168Point = info.Bonus
          // const mbr168Point = window.document.getElementById(
          //   'mbr168Point'
          // ) as HTMLElement | null;
          // if (mbr168Point) {
          //   mbr168Point.innerText = x.result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          // }   // 將值賦給 mbr168Point 變數
          // if (this.mbr168Point) {
          //   this.mbr168Point = x.result.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          //   }
            // console.log(info.Bonus)
            // console.log(this.mbr168Point)
          // this.$point.next(info.Bonus);
          this.SetLocalInfo(info);
          subscriber.next('success');
          subscriber.complete();
        });
    });
  }
  SetCookie(name: string, value: string) {
    document.cookie = name + '=' + value;
  }
  ClearCookie() {
    document.cookie = '';
  }
  //aes加密
  encryptByEnAES(data: string): string {
    let tmpAES = AES.encrypt(data, this.encryptKey, {
      mode: mode.CBC,
      padding: pad.Pkcs7,
    });
    return tmpAES.toString();
  }
  encryptByDeAES(data: string): string {
    let tmpDeAES = AES.decrypt(data, this.encryptKey, {
      mode: mode.CBC,
      padding: pad.Pkcs7,
    });
    return tmpDeAES.toString(enc.Utf8);
  }
}
