import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Brand } from 'src/app/shared/model/product';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';
import { ParentBrandService } from 'src/app/shared/service/parent-brand.service';
import { HeaderService } from 'src/app/shared/service/header.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss']
})
export class ProductOverviewComponent implements OnInit {
  sourceBrands: Brand[] = [];
  viewBrands: Brand[] = [];

  keyword: string;
  constructor(private httpService: HttpService,
    private loaderService: LoaderService,
    private pagetagService: PagetagService,
    private router: Router,
    private parentBrandService: ParentBrandService,
    public headerService:HeaderService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.search();
    this.callPagetagService();
    this.headerService.Footer =true;
  }
  ngOnDestroy(): void {
    this.headerService.Footer =false;

  }
  search() {
    this.loaderService.start();
    this.httpService.get("/Product/CatLIST").subscribe((x: any) => {
      this.sourceBrands = x;

      let parentBrandList: Brand[] = [];
      this.sourceBrands.forEach(item => {
        parentBrandList.push({ id: item.id, name: item.name });
      })
      this.setParentBrandList(parentBrandList);

      this.viewBrands = JSON.parse(JSON.stringify(this.sourceBrands));
      this.viewBrands = this.viewBrands.filter(a => a.list!.length > 0 && a.name != '所有品牌');

      this.loaderService.stop();
    });
  }

  filter() {
    this.loaderService.start();
    this.viewBrands = JSON.parse(JSON.stringify(this.sourceBrands));;
    if (this.keyword && this.keyword.trim().length > 0) {
      this.viewBrands.forEach(a => a.list = a.list!.filter(b => b.name2.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1));
    }
    this.viewBrands = this.viewBrands.filter(a => a.list!.length > 0);
    this.loaderService.stop();
  }

  callPagetagService() {
    this.pagetagService.callPageTag(this.router.url, '品牌館')
  }

  setParentBrandList(brands: Brand[]) {
    this.parentBrandService.parentBrandList.next(brands);
  }
  goback() {
    this.location.back();
  }
}
