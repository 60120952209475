import {
  Cart,
  CheckoutReq,
  CommonAddress,
  PosForm,
  DeleteCartReq,
  TWPayForm,
  CreateCart,
} from './../../../shared/model/cart';
import { LoginService } from './../../../shared/service/login.service';
import { HttpService } from './../../../shared/service/http.service';
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
import { HeaderService } from '../../../shared/service/header.service';
import { counties } from '../../../shared/data/counties';
import { zipcode } from '../../../shared/data/zipcode';
import { environment } from '../../../../environments/environment';
import { CartService } from '../../../shared/service/cart.service';
import { LoaderService } from '../../../shared/service/loader.service';
import { MbrInfo } from '../../../shared/model/member';
import { trim } from 'jquery';
import { PagetagService } from '../../../shared/service/pagetag.service';
import { checkIsMobileDevice } from '../../../shared/helpers/device-helper';
import { ProductType } from '../../../shared/model/product';
import { addThouthandSeparator } from '../../../shared/helpers/format-helper';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
declare var iCashJSContext: any;
@Component({
  selector: 'app-member-cart',
  templateUrl: './member-cart.component.html',
  styleUrls: ['./member-cart.component.scss'],
})
export class MemberCartComponent implements OnInit, OnDestroy {
  paymentMethod: number = 1;
  isAddressModalOpen: boolean = false;
  isAddressCheckboxChecked: boolean = false;
  isLasttimeCheckbox: boolean = false;
  //isStoredinvoiceCheckbox: boolean = true;
  isReadpersonalCheckbox: boolean = true;
  isReturnCheckbox: boolean = true;

  // 是否有公益類型商品
  hasCharityTypeProduct = false;
  IsCharityAnonymousCheckboxChecked = false;

  //alertModal
  isAlertModalOpen: boolean = false;
  alertMsg: string = '';
  //confirmModal
  confirmModalStep = 1;
  isComfirmModalOpen: boolean = false;
  preConfirmMessage = '';
  confirmMessege: string = '';

  isPrivacyModalOpen = false;

  isPointModalOpen = false;
  //deleteModal
  isDeleteModalOpen: boolean = false;
  isAddCollect: boolean;
  deleteModalData: Cart = new Cart();

  deleteCartReq = new DeleteCartReq();
  // 手機版應支付點數tooltip
  isMobileTooltipOpen = false;
  //限制點數
  limitVBonus: number = 0;
  limitCardBonus: number = 0;
  //購物步驟
  cartProcess = 0;
  //發票類型
  receiptType = 2;
  receiptInfoInput2: string = ''; // 手機條碼
  isReceiptInfoInput2Changed: boolean = false;
  receiptInfoInput3: string = ''; // 自然人憑證
  isReceiptInfoInput3Changed: boolean = false;
  receiptInfoInput5Name: string = ''; // 公司名稱
  receiptInfoInput5Number: string = ''; // 公司統編
  isCompanyNumberPass: boolean = false; // 公司統編驗證是否通過
  infos: Cart[];
  checkoutReq: CheckoutReq = new CheckoutReq();
  totalPrice: number = 0;
  totalPoint: number = 0;
  RGDiscountPoint: number = 0;
  FDiscountPoint: number = 0;
  erroeMsg: string;
  isHasDelivery: boolean;
  isHasGoldFlow: boolean;
  // posUrl = environment.posUrl;
  // twPayUrl = environment.twPayUrl;
  uuPayUrl = environment.uuPayUrl;
  posForm = new PosForm();
  TWPayForm = new TWPayForm();
  checked = false;
  isCheckModalOpen = false;
  //常用地址相關
  commonAddressInfo: CommonAddress[];
  selectedAddressIndex: number;
  exchangeTypeList: any = [];
  exchangeType: any;
  amount: number = 0;
  // deep copy
  counties = JSON.parse(JSON.stringify(counties));
  zipcode = JSON.parse(JSON.stringify(zipcode));
  selectedZipcodeForReceiverAddress: {
    id: string;
    zipcode: number;
    county: string;
    city: string;
  }[] = [];
  selectedZipcodeForReceiptAddress: {
    id: string;
    zipcode: number;
    county: string;
    city: string;
  }[] = [];
  numbertem = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  cartNumber: number = 0;
  userInfo: MbrInfo;
  isMobileDevice = false;
  buy: any;
  check: any;
  calculator: any;
  isCheckoutClick = false;
  //控制購買API只呼叫一次
  checkoutStatus: boolean = true;
  Donate = true;
  constructor(
    private httpService: HttpService,
    private loginService: LoginService,
    private cartService: CartService,
    private loaderService: LoaderService,
    private router: Router,
    private pagetagService: PagetagService,
    private location: Location,
    public headerService: HeaderService
  ) {
    // this.headerService.Global = false;
    this.headerService.Buy = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileDevice = checkIsMobileDevice();
  }

  ngOnInit(): void {
    this.isMobileDevice = checkIsMobileDevice();
    this.userInfo = this.loginService.GetLocalInfo();
    // if (this.userInfo.IsLogin) {
    this.carList(this.userInfo.ID);
    this.getLastInvoiceInfo();
    this.getLastOrderAddress();

    // 取得購物車數量
    // this.cartService.getCartNumber();
    // 綁定購物車數量至元件屬性
    // this.cartService.cartNumber.subscribe(num => {
    //   this.cartNumber = num;
    // })

    this.callPagetagService();
  }
  ngOnDestroy(): void {
    this.headerService.Buy = false;
  }
  amountChange(event: any, Cart: Cart) {
    if (Cart.amount || Cart.amount == 0) {
      if (Cart.amount < 1) {
        Cart.amount = 1;
      }
      if (Cart.amount > 10) {
        Cart.amount = 10;
      }
    }

    // if (this.calculator) {
    //   clearTimeout(this.calculator);
    // }
    // this.calculator = setTimeout(() => {
    //   this.calcTotalPriceAndPoint();
    // }, 1000);
    this.calcTotalPriceAndPoint();
  }
  isReceiptInfoInputValid(val: string) {
    val = val.trim();

    let regex: RegExp;
    let result: boolean = false;
    // 避免reciptType 透過select 賦值後改變型別
    switch (parseInt(this.receiptType.toString())) {
      case 2:
        regex = /^\/[a-z,A-Z,0-9.+-]{7}$/;
        result = regex.test(val);
        break;
      case 3:
        regex = /[A-Z]{2}[0-9]{14}$/;
        result = regex.test(val);
        break;
    }
    return result;
  }

  resetReceiptInfoInput() {
    this.isReceiptInfoInput2Changed = false;
    this.isReceiptInfoInput3Changed = false;
    this.receiptInfoInput2 = '';
    this.receiptInfoInput3 = '';

    // if (this.receiptType == 4 && !this.hasCharityTypeProduct) {
    //   this.checkoutReq.InvoiceName = undefined;
    //   this.checkoutReq.InvoicePhone = undefined;
    //   this.checkoutReq.InvoiceArea = '';
    //   this.checkoutReq.InvoiceCity = '';
    //   this.checkoutReq.InvoiceAddress = undefined;
    // }
  }
  onInputChange() {
    this.receiptInfoInput2 = this.receiptInfoInput2.toUpperCase();
    this.isReceiptInfoInput2Changed = true;
  }
  // paymentMethodChange(num: number) {
  //   this.paymentMethod = num;
  // }

  addressClick($event: any) {
    $event.preventDefault();
    this.isAddressModalOpen = true;
  }

  carList(mbrID: any) {
    this.loaderService.start();
    let cart = sessionStorage.getItem('Cart') || '';
    if (cart.length == 0) {
      this.router.navigate(['/']);
    }
    const x: CreateCart = JSON.parse(cart);
    this.cartNumber = x.Amount;
    const qtime = new Date().getTime().toString();
    this.httpService
      .get(
        '/CartNew/carlist?productID=' + x.ProductDtlId + '&amount=' + x.Amount
      )
      .subscribe(
        (x: any) => {
          this.infos = x;
          // 兌換方式 預設值
          this.exchangeTypeList = x[0].exchangeTypes;
          this.exchangeType = this.exchangeTypeList[0];
          this.checkoutReq.PaymentMethod = this.exchangeType.type;

          //判斷是否顯示宅配商品輸入框
          if (this.infos.findIndex((x) => x.productType == '宅配商品') > -1) {
            if (!this.isHasDelivery) {
              this.getCommonAddress();
            }
            this.isHasDelivery = true;
          }
          //判斷是否顯示金流輸入框
          if (this.infos.findIndex((x) => x.payType == 2) > -1) {
            this.isHasGoldFlow = false;
          }
          // 判斷是否有公益類型商品
          this.checkIfHasCharityTypeProduct(this.infos);

          this.calcTotalPriceAndPoint();
          this.calcLimitPoint();
          // this.UpdateCartActivityOffer(x.productID);

          // this.isPrivacyModalOpen = true;//隱私權註解掉
        },
        (msg) => alert('error' + msg),
        () => this.loaderService.stop()
      );
  }

  checkIfHasCharityTypeProduct(infos: any[]) {
    if (!Array.isArray(infos)) {
      this.hasCharityTypeProduct = false;
      this.IsCharityAnonymousCheckboxChecked = false;
      return;
    }

    let _hasCharityTypeProduct = false;
    infos.forEach((item) => {
      if (item.productTypeCodeID == ProductType.公益類型) {
        _hasCharityTypeProduct = true;
      }
    });
    this.hasCharityTypeProduct = _hasCharityTypeProduct;
    if (!_hasCharityTypeProduct) {
      this.IsCharityAnonymousCheckboxChecked = false;
    }
  }

  calc(item: Cart, type: string) {
    if (type == '+') {
      if (item.amount == 20) {
        this.alertMsg = '同商品單次購買上限為20件';
        this.isAlertModalOpen = true;
        return;
      }
      if (item.amount < item.remainingProductNum) {
        item.amount = item.amount + 1;
        this.changeCartAmount(item, 'add');
      } else {
        this.alertMsg = '此商品剩餘數量已至上限，無法再加入購物車';
        this.isAlertModalOpen = true;
      }
    } else {
      if (item.amount > 1) {
        item.amount = item.amount - 1;
        this.changeCartAmount(item, 'reduce');
      }
    }
    if (this.calculator) {
      clearTimeout(this.calculator);
    }
    this.calculator = setTimeout(() => {
      this.calcTotalPriceAndPoint();
    }, 1000);
  }

  initDeleteModal(item: Cart, isAddCollect: boolean) {
    this.deleteModalData = item;
    this.isDeleteModalOpen = true;
    this.deleteCartReq.isAddCollect = isAddCollect;
  }

  deleteCart(item: Cart) {
    this.deleteCartReq.Ids = [];
    this.deleteCartReq.Ids.push(item.cartID);
    this.httpService
      .post('/CartNew/Delete', this.deleteCartReq)
      .subscribe((x: any) => {
        var otherInfos = this.infos.filter((x) => x.cartID != item.cartID);
        this.infos = otherInfos;
        this.isHasDelivery =
          this.infos.findIndex((x) => x.productType == '宅配商品') > -1;
        this.isHasGoldFlow = this.infos.findIndex((x) => x.payType == 2) > -1;

        // 判斷是否還有公益類型商品
        this.checkIfHasCharityTypeProduct(this.infos);

        this.cartNumber = x.amount;
        // 重新取得購物車數量
        // this.cartService.getCartNumber();
        this.calcTotalPriceAndPoint();
        this.calcLimitPoint();

        this.isDeleteModalOpen = false;
      });
  }
  calcTotalPriceAndPoint() {
    this.totalPoint = 0;
    this.totalPrice = 0;
    this.infos
      .filter((x) => x.remainingProductNum > 0)
      .forEach(
        (row) => (this.totalPoint += this.exchangeType.point * row.amount)
      );
    this.infos
      .filter((x) => x.remainingProductNum > 0)
      .forEach(
        (row) => (this.totalPrice += this.exchangeType.price * row.amount)
      );
  }
  changeCartAmount(item: Cart, type: string) {
    this.httpService
      .get(
        '/CartNew/ChangeCartAmount?cartID=' +
          item.cartID +
          '&type=' +
          type +
          '&number=' +
          item.amount
      )
      .subscribe((x: any) => {
        if (x.cCode != 0) {
          if (x.cCode > 0) {
            this.alertMsg = x.cMsg;
            this.isAlertModalOpen = true;
          }
          return;
        } else {
          this.FDiscountPoint = x.cFDiscountPoint;
          this.RGDiscountPoint = x.cRGDiscountPoint;
          // this.totalPoint = x.cTotalPoint;
          // this.totalPrice = x.cTotalPrice;
        }
        this.calcTotalPriceAndPoint();
        this.calcLimitPoint();
      });
  }
  // UpdateCartActivityOffer(proID: any) {//mbrID: any
  //   this.httpService.get("/CartNew/CartActivityOffer?str=" + proID).subscribe((x: any) => {
  //     if (x.cCode != 0) {
  //       if (x.cCode > 0) {
  //         this.alertMsg = x.cMsg;
  //         this.isAlertModalOpen = true;
  //       }
  //       return;
  //     }
  //     else {
  //       this.FDiscountPoint = x.cFDiscountPoint;
  //       this.RGDiscountPoint = x.cRGDiscountPoint;
  //       // this.totalPoint = x.cTotalPoint;
  //       // this.totalPrice = x.cTotalPrice;
  //     }
  //   });
  // }

  checkprivacy(): string {
    var msg = '';
    if (!this.isReadpersonalCheckbox) {
      msg += '在您進入交易前，請您先閱讀及接受 悠客券 服務條款及隱私權政策。br';
    }
    if (!this.isReturnCheckbox) {
      msg += '請確認是否同意由精誠資訊股份有限公司代為處理發票及銷貨退回證明。';
    }
    return msg;
  }

  goResult() {
    // this.isPointModalOpen = true;
    const product = sessionStorage.getItem('Cart');
    if (!!product) {
      const item = JSON.parse(product);
      this.router.navigate([`member/order/detail/${item.ProductId}`]);
    }
  }

  sendValidate() {
    if (this.infos.some((x) => !x.amount)) {
      this.alertMsg = '請填寫商品數量';
      this.isAlertModalOpen = true;
      return;
    }
    if (
      this.totalPoint > this.userInfo.Bonus &&
      this.checkoutReq.PaymentMethod == 2
    ) {
      this.isPointModalOpen = true;
      return;
    }

    let msg = '';

    this.calcTotalPriceAndPoint();
    this.calcLimitPoint();
    if (this.isHasDelivery) {
      if (!this.checkoutReq.Recipient) {
        msg += '收件人,';
      }
      if (!this.checkoutReq.Tel) {
        msg += '收件人電話,';
      }
      if (
        !this.checkoutReq.City ||
        !this.checkoutReq.Area ||
        !this.checkoutReq.Address
      ) {
        msg += '收件地址,';
      }
    }
    if (!this.checkoutReq.Email) {
      msg += '聯繫E-mail,';
    }
    if (false) {
      if (!this.checkoutReq.VBonus && !this.checkoutReq.CardBonus) {
        msg += '支付點數,';
      }
    }
    if(this.checkoutReq.PaymentMethod ==2 && this.isHasGoldFlow){
      //&& !(this.receiptType == 4 && !this.hasCharityTypeProduct)
        if (!this.checkoutReq.InvoiceName || !/^\s*\S/.test(this.checkoutReq.InvoiceName)) {
          msg += '發票姓名,';
        }

        if (!this.checkoutReq.InvoicePhone || !/^\s*\S/.test(this.checkoutReq.InvoicePhone)) {
          msg += '發票聯絡電話,';
        }
        if (
          !this.checkoutReq.InvoiceCity ||
          !this.checkoutReq.InvoiceArea ||
          !this.checkoutReq.InvoiceAddress || !/^\s*\S/.test(this.checkoutReq.InvoiceAddress)
        ) {
          msg += '發票地址,';
        }
    }

    if (msg != '') {
      msg = msg.substring(0, msg.length - 1);
      msg += ' 為必填項目 br';
    }

    if (trim(this.checkoutReq.Recipient).length > 20) {
      msg += '收件人字數不可超過20字，請重新確認 br';
    }

    if (false) {
      if (
        this.checkoutReq.CardBonus + this.checkoutReq.VBonus <
        this.totalPoint
      ) {
        msg += '支付點數不足，請重新確認 br';
      }
      if (this.checkoutReq.VBonus < this.limitVBonus) {
        msg += '悠遊付點數 支付不足 br';
      }
      if (this.checkoutReq.CardBonus < this.limitCardBonus) {
        msg += '悠客券平台 支付不足 br';
      }
    }
    if (!this.checkoutReq.PaymentMethod) {
      msg += '請選擇兌換方式 br';
    }

    if
    (!this.checkoutReq.Email)
     msg += '請輸入電子信箱 br';
    else if (
      !new RegExp(
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
      ).test(this.checkoutReq.Email)
    ) {
      msg += 'Email格式錯誤 br';
    }

    if(this.checkoutReq.PaymentMethod ==2){
      if
      (!this.checkoutReq.InvoicePhone)
      msg += '請輸入聯絡電話 br';
      else if (
        !new RegExp(
          /^\d{10}$/
        ).test(this.checkoutReq.InvoicePhone)
      ) {
        msg += '手機格式錯誤 br';
      }
    }

    if (
      this.checkoutReq.PaymentMethod ==2 &&
      this.receiptType == 2 &&
      !this.isReceiptInfoInputValid(this.receiptInfoInput2)
    ) {
      if (this.receiptInfoInput2.trim().length > 0) {
        msg += '請輸入正確的手機載具格式 br';
      } else {
        msg += '請於發票資訊區塊輸入手機條碼內容 br';
      }
    }
    if (
      this.checkoutReq.PaymentMethod ==2 &&
      this.receiptType == 3 &&
      !this.isReceiptInfoInputValid(this.receiptInfoInput3)
    ) {
      if (this.receiptInfoInput3.trim().length > 0) {
        msg += '請輸入正確的自然人憑證格式 br';
      } else {
        msg += '請於發票資訊區塊輸入自然人憑證內容 br';
      }
    }

    if (msg != '') {
      this.alertMsg = msg;
      this.isAlertModalOpen = true;
      return;
    }

    var msgCheckprivacy = this.checkprivacy();
    if (msgCheckprivacy.length > 0) {
      this.alertMsg = msgCheckprivacy;
      this.isAlertModalOpen = true;
      return;
    }
    if (this.totalPrice == 0 && this.isHasGoldFlow) {
      this.alertMsg = '訂單金額不可為0';
      this.isAlertModalOpen = true;
      return;
    }

    if(this.checkoutReq.PaymentMethod == 1){
    if(this.loginService.mbr168Point < this.totalPoint){
      this.isPointModalOpen =true;
      return;
    }
  }
    //confirm文字
    // this.preConfirmMessage = `
    //   您好br/
    //   您此次的訂單${!this.isHasGoldFlow ? '' : '金額'}如下，請確認:br/
    //   ${!this.isHasGoldFlow
    //     ? ''
    //     : `支付方式:${this.paymentMethod == 1
    //       ? '信用卡'
    //       : this.paymentMethod == 2
    //         ? '台灣Pay'
    //         : this.paymentMethod == 3
    //           ? '悠遊付'
    //           : ''
    //     }br/`
    //   }
    // ${!this.isHasGoldFlow ? '' : `支付金額:${this.totalPrice}元br/`}`;
    // 支付點數:悠遊付點數 ${this.checkoutReq.VBonus}點、信用卡點數${this.checkoutReq.CardBonus}點br/`;

    if (this.infos.filter((x) => x.remainingProductNum <= 0).length > 0) {
      this.confirmMessege =
        '購物車內存在已無庫存商品br/結帳時系統將自動從購物車移除並加入收藏清單br/是否確定送出';
    } else {
      this.confirmMessege = '是否確定送出';
    }
    // this.isComfirmModalOpen = true;
    this.checkout();
  }

  goback() {
    this.location.back();
  }
  checkout() {
    //TODO：悠遊付登入API //
    // this.checkMember();
    if (this.checkoutStatus) {
      this.doCheckout();
      this.checkoutStatus = false;
    }
  }

  phoneCheck(e: KeyboardEvent) {
    //電話號碼限10碼 純數字
    let old = this.checkoutReq.InvoicePhone;
    setTimeout(() => {
      if (
        this.checkoutReq.InvoicePhone &&
        this.checkoutReq.InvoicePhone.length > 10
      ) {
        if (old && old.length > 10) old = old.substring(0, 10);
        this.checkoutReq.InvoicePhone = old;
      }
      if (e.keyCode < 48 || e.keyCode > 57) {
        this.checkoutReq.InvoicePhone = old;
      }
    }, 5);
  }
  doCheckout() {
    //TODO：悠遊付登入API //

    // modal 拿掉，改成loader
    this.isComfirmModalOpen = false;
    this.loaderService.start();
    //判斷交易token是否逾期
    // this.loginService.ResetToken(this.userInfo.Token).subscribe((x: string) => {
    // switch (x) {
    //   case 'success':
    //     this.userInfo = this.loginService.GetLocalInfo();
    //     break; //刷新成功
    //   case 'A505':
    //     this.loginService.Logout();
    //     break; //>60分鐘
    //   case 'A529':
    //     break; //<50分鐘
    // }
    if (this.isHasGoldFlow) {
      // this.checkoutReq.PaymentMethod = this.paymentMethod;
      this.checkoutReq.InvoiceMethod = this.receiptType;
      // this.checkoutReq.InvoiceTo =
      //   this.receiptType == ReceiptType.Mail
      //     ? this.checkoutReq.Email
      //     : this.receiptType == ReceiptType.Barcode
      //     ? this.receiptInfoInput2
      //     : this.receiptType == ReceiptType.CitizenCertificate
      //     ? this.receiptInfoInput3
      //     : this.receiptType == ReceiptType.Charity
      //     ? ''
      //     : '';

      const type: number = Number(this.receiptType);
      switch (type) {
        //電子信箱
        case 1:
          this.checkoutReq.InvoiceTo = this.checkoutReq.Email;
          break;
        //手機條碼
        case 2:
          this.checkoutReq.InvoiceTo = this.receiptInfoInput2;
          break;
        //自然人憑證
        case 3:
          this.checkoutReq.InvoiceTo = this.receiptInfoInput3;
          break;
        //發票捐贈
        case 4:
        default:
          this.checkoutReq.InvoiceTo = '';
          break;
      }
    }
    this.checkoutReq.MbrId = this.userInfo.ID;
    this.checkoutReq.MbrToken = this.userInfo.Token;
    this.checkoutReq.MbrName = this.userInfo.Name;
    this.checkoutReq.EmpNo = this.userInfo.EmpNo;
    this.checkoutReq.IsAddCommonAddress = this.isAddressCheckboxChecked;
    //若輸入點數為空 則帶入0
    this.checkoutReq.VBonus = this.checkoutReq.VBonus ?? 0;
    this.checkoutReq.CardBonus = this.checkoutReq.CardBonus ?? 0;

    // 確認扣除無庫存商品後，是否還有公益商品
    let restProducts = this.infos.filter((x) => x.remainingProductNum > 0);
    this.checkIfHasCharityTypeProduct(restProducts);
    this.checkoutReq.IsCharityAnonymous =
      this.IsCharityAnonymousCheckboxChecked;
    this.checkoutReq.hasCharityTypeProduct = this.hasCharityTypeProduct;
    this.checkoutReq.PID = this.exchangeType.id;
    this.checkoutReq.Amount = this.infos[0].amount;
    this.checkoutReq.SN = this.exchangeType.sn;

    // 先排除已無庫存之購物車

    this.infos
      .filter((x) => x.remainingProductNum <= 0)
      .forEach((x) => this.deleteCartReq.Ids.push(x.cartID));
    this.deleteCartReq.isAddCollect = true;
    this.httpService
      .post('/CartNew/Delete', this.deleteCartReq)
      .subscribe((qq: any) => {
        this.httpService
          .authPost('/CartNew/Checkout', this.checkoutReq)
          .subscribe((x: any) => {
            this.loaderService.stop();
            this.checkoutStatus = true;
            if (x.code == 1) {
              //純點
              this.cartProcess = 1;
              //聯繫E-mail
              var email = x.result.split('|')[1];
              const orderMainNo = x.result.split('|')[2];
              this.loginService.ResetBonus().subscribe();
              this.router.navigate(['/member/order/detail', orderMainNo]);
              // location.href = '/member/cart/result?state=1&msg=' + email;
            } else if (x.code == 2) {
              this.icashAppPay(x.result);
            } else {
              //Exception
              this.cartProcess = 2;
              this.erroeMsg = x.result;
              location.href = '/member/cart/result?state=2&msg=' + x.result;
            }
          });
      });
    // });
  }
  //判斷會員分配點數與計算
  distributeMbrPoint(inputPointType: string) {
    if (inputPointType == 'VBonus') {
      var inputNumber = this.checkoutReq.VBonus;
      if (inputNumber) {
        if (this.totalPoint < inputNumber) {
          inputNumber = this.totalPoint;
        }
        if (this.userInfo.Bonus < inputNumber) {
          inputNumber = this.userInfo.Bonus;
        }
        this.checkoutReq.CardBonus =
          this.totalPoint - inputNumber > this.userInfo.CardBonus
            ? this.userInfo.CardBonus
            : this.totalPoint - inputNumber;
        this.checkoutReq.VBonus = inputNumber;
      }
    }
    if (inputPointType == 'CardBonus') {
      var inputNumber = this.checkoutReq.CardBonus;
      if (inputNumber) {
        if (this.totalPoint < inputNumber) {
          inputNumber = this.totalPoint;
        }
        if (this.userInfo.CardBonus < inputNumber) {
          inputNumber = this.userInfo.CardBonus;
        }
        this.checkoutReq.VBonus =
          this.totalPoint - inputNumber > this.userInfo.Bonus
            ? this.userInfo.Bonus
            : this.totalPoint - inputNumber;
        this.checkoutReq.CardBonus = inputNumber;
      }
    }
  }

  filterNumberValue(event: any) {
    event.preventDefault();
    const reg = new RegExp(/(^[0]{1}$)|(^[1-9][0-9]*$)/);
    const val = event.target.value;

    if (!val && val.toString() != '0') {
      event.target.value = null;
      return;
    }
    if (!reg.test(val.toString())) {
      event.target.value = parseInt(val.toString().slice(0, -1));
      return;
    }
  }

  avoidNumberTypeChar(event: any) {
    var invalidChars = ['-', '+', 'e'];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  getCommonAddress() {
    this.httpService
      .get('/CartNew/commonAddress?str=' + this.userInfo.ID)
      .subscribe((x: any) => {
        this.commonAddressInfo = x;
      });
  }
  deleteCommonAddress(id: number) {
    this.httpService
      .get('/CartNew/deleteCommonAddress?id=' + id)
      .subscribe((x: any) => {
        this.getCommonAddress();
      });
  }

  setCartStepCompleted(step: number) {
    this.cartProcess = step;
    let cart: CreateCart = JSON.parse(sessionStorage.getItem('Cart') || '');
    const url = `/product/detail/${cart.ProductId}`;

    if (!url || typeof url != 'string' || url.trim().length == 0) {
      this.router.navigate(['/product', 'overview']);
      return;
    }
    this.router.navigate([url]);
    sessionStorage.removeItem('Cart');
  }

  // 地址欄位選擇縣市後過濾區域
  zipcodeFilter(targetCity: string, type: string) {
    // 清空過濾陣列
    switch (type) {
      case 'receiver':
        this.selectedZipcodeForReceiverAddress = [];

        this.zipcode.forEach((item: any) => {
          if (item.county == targetCity) {
            this.selectedZipcodeForReceiverAddress.push(item);
          }
        });
        break;
      case 'receipt':
        this.selectedZipcodeForReceiptAddress = [];

        this.zipcode.forEach((item: any) => {
          if (item.county == targetCity) {
            this.selectedZipcodeForReceiptAddress.push(item);
          }
        });
        break;
      default:
        break;
    }
  }

  chooseCommonAddress($event: any) {
    if ($event.target.checked) {
      this.selectedAddressIndex = $event.target.value;
    }
  }

  confirmCommonAddress(modalOpen: boolean) {
    this.isAddressModalOpen = modalOpen;
    //填入常見地址資訊
    var data = this.commonAddressInfo[this.selectedAddressIndex];
    this.checkoutReq.Recipient = data.cRecipent;
    this.checkoutReq.Tel = data.cRecipentTel;
    this.checkoutReq.City = data.cCity;
    this.zipcodeFilter(data.cCity, 'receiver');
    this.checkoutReq.Area = data.cArea + '|' + data.cZipCode;
    this.checkoutReq.Address = data.cAddress;
  }
  calcLimitPoint() {
    if (this.infos) {
      if (
        this.infos.filter((x) => x.limitPointType == '悠遊付點數').length > 0
      ) {
        this.limitVBonus = this.infos
          .filter((x) => x.limitPointType == '悠遊付點數')
          .map((x) => x.amount * x.point)
          .reduce((a, b) => a + b);
      } else {
        this.limitVBonus = 0;
      }
      if (
        this.infos.filter((x) => x.limitPointType == '悠遊付點數').length > 0
      ) {
        this.limitCardBonus = this.infos
          .filter((x) => x.limitPointType == '悠遊付點數')
          .map((x) => x.amount * x.point)
          .reduce((a, b) => a + b);
      } else {
        this.limitCardBonus = 0;
      }
    }
  }
  getLastOrderAddress() {
    this.httpService
      .get('/CartNew/GetLastOrderAddress?mbrID=' + this.userInfo.Token)
      .subscribe((x: any) => {
        if (x.indexOf('取得信箱錯誤') > -1) {
          this.alertMsg = x;
          this.isAlertModalOpen = true;
        }
        this.checkoutReq.Email = x;
      });
  }

  getLastInvoiceInfo() {
    this.httpService
      .get('/CartNew/GetLastInvoiceInfo?mbrID=' + this.userInfo.Token)
      .subscribe((x: any) => {
        if (x) {
          const invoiceInfo = x == 'FirstPurchase' ? x : JSON.stringify(x);
          sessionStorage.setItem('InvoiceInfo', invoiceInfo);
          this.changeInvoiceType(Number(x.invoiceType));
        }
      });
  }
  changeInvoiceType(invoiceType: number) {
    let tempInvoice = sessionStorage.getItem('InvoiceInfo');
    this.checkoutReq.IsAgreeInvoiceInfo = true;

    if (!tempInvoice || tempInvoice.length == 0) {
      //this.alertMsg = '無上一筆發票資訊';
      //this.isAlertModalOpen = true;
      return;
    }
    //從未有訂單則顯示隱私條款資訊
    if (tempInvoice == 'FirstPurchase') {
      this.isPrivacyModalOpen = true;
      return;
    }

    let dataInv = JSON.parse(tempInvoice);
    if (dataInv.invoiceInfoData) {
      this.checkoutReq.InvoiceName = dataInv.invoiceInfoData.invoiceName;
      this.checkoutReq.InvoicePhone = dataInv.invoiceInfoData.invoicePhone;
      this.checkoutReq.InvoiceCity = dataInv.invoiceInfoData.invoiceCity;
      this.zipcodeFilter(dataInv.invoiceInfoData.invoiceCity, 'receipt');
      this.checkoutReq.InvoiceArea =
        dataInv.invoiceInfoData.invoiceArea +
        '|' +
        dataInv.invoiceInfoData.invoiceZipCode;
      this.checkoutReq.InvoiceAddress = dataInv.invoiceInfoData.invoiceAddress;
      // this.checkoutReq.IsAgreeInvoiceInfo = dataInv.isAgreeInvoiceInfo;
      this.receiptType = Number(invoiceType);
      setTimeout(() => {
        switch (Number(invoiceType)) {
          //信箱
          case 1:
            this.checkoutReq.Email = dataInv.invoiceTo1;
            break;
          //手機條碼
          case 2:
            this.receiptInfoInput2 = dataInv.invoiceTo2;
            break;
          //自然人憑證
          case 3:
            this.receiptInfoInput3 = dataInv.invoiceTo3;
            break;
          //發票捐贈
          case 4:
            break;
        }
      }, 10);
      // } else {
    }
  }


  checkCompanyNumber() {
    this.httpService
      .get('/CartNew/CheckCompanyNumber?companyNumber=' + this.receiptInfoInput5Number)
      .subscribe((x: any) => {
        this.isCompanyNumberPass = x;
      });
  }

  checkPointInfo($event?: any) {
    if ($event) {
      $event.preventDefault();
    }

    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/member/point`])
    );

    window.open(url, '_blank');
  }

  callPagetagService() {
    this.pagetagService.callPageTag(this.router.url, '會員服務 | 購物車');
  }

  addThouthandMark(num: number) {
    return addThouthandSeparator(num);
  }

  handlePrivacyLinkClick(e: any) {
    e.preventDefault();
    this.isPrivacyModalOpen = true;
  }
  exchangeTypeChange() {
    this.exchangeType = this.exchangeTypeList.filter(
      (x: any) => x.type == this.checkoutReq.PaymentMethod
    )[0];
    if(this.exchangeType.type==2){
      this.isHasGoldFlow = true;
    }
    if(this.exchangeType.type==1){
      this.isHasGoldFlow = false;
    }
    console.log(this.exchangeType);
    this.calcTotalPriceAndPoint();
  }
  icashAppPay(tradeToken: string) {
    try {
      iCashJSContext.call('OpenWebActivity', tradeToken, '3tgds');
    } catch (e: any) {
      alert(e);
    }
  }
}
enum ReceiptType {
  Mail = 1,
  Barcode = 2,
  CitizenCertificate = 3,
  Charity = 4,
}
