<div class="activity">
  <div class="date">{{ActivityRes.activity?.cSDate | date: 'yyyy-MM-dd'}} ~ {{ActivityRes.activity?.cEDate | date:
    'yyyy-MM-dd'}}</div>
  <div class="title" (click)="change()">{{ActivityRes.activity?.cName}}</div>
  <div class="sub_title">{{ActivityRes.activity?.cSubTitle}}</div>
  <div class="box">
    <!-- 每日 -->
    <div class="img" *ngIf="ActivityRes.activitySignIn?.cMode == 1">
      <div class="img__title">簽到完成</div>

      <img class="img__gift" src="/assets/images/icashpay-icon/sign-in-gift.png" alt="">
      <div class="img__content" *ngFor="let signin of ActivityRes.signInDetail; let i = index">
        恭喜獲得<span style="color: #F68B1F;">{{signin.cSignInPoint}}</span>點數</div>
    </div>
    <!-- 連續 -->
    <div class="img" *ngIf="ActivityRes.activitySignIn?.cMode == 2">
      <div class="img__title" style="padding-top:40px; font-size: 16px;"><a
          *ngIf="ActivityRes.memberInfo.cIsSignIn">簽到完成</a><a *ngIf="!ActivityRes.memberInfo.cIsSignIn">今日尚未簽到</a></div>
      <div class="img__content" style="padding-bottom: 0px; padding-top: 10px;">
        已連續簽到{{ActivityRes.memberInfo.cSignDay}}天</div>
      <div class="img-box"  [ngClass]="{'img-box-5':this.signDay == 5}">
        <ng-container *ngFor="let signin of ActivityRes.signInDetail; let i = index">
          <div
            [ngClass]="{'img__signin':ActivityRes.memberInfo.cSignDay==i && !ActivityRes.memberInfo.cIsSignIn,
            'img__signedin': ActivityRes.memberInfo.cSignDay > i,'img__not-signedin':( ActivityRes.memberInfo.cSignDay <= i&& ActivityRes.memberInfo.cIsSignIn)||(ActivityRes.memberInfo.cSignDay < i&&!ActivityRes.memberInfo.cIsSignIn)}">
            <div class="day">第{{signin.cDay}}天</div>
            <img src="/assets/images/icashpay-icon/p.png" alt="">
            <div class="point">+{{signin.cSignInPoint}}點</div>
          </div>
        </ng-container>
      </div>
      <!-- [ngClass]="{'img__signedin': signin.isSignin === true, 'img__not-signedin': signin.isSignin === false && i != 2}" -->
      <button class="btn" [ngClass]="{'btn__signin':!ActivityRes.memberInfo.cIsSignIn}" (click)="signin()">{{isSignin ?
        '簽到' :
        '已簽到'}}</button>
    </div>
  </div>
  <div class="log">
    <div class="text" (click)="goto('activity/log')">
     <div class="img-box">活動紀錄<img src="/assets/images/icashpay-icon/right-arrow.png"
      alt="right-arrow"></div>
    </div>
  </div>

  <div class="rule" *ngIf="!isOpen">
    <div class="rule__title">活動規則</div>
    <div class="rule__content one-line-text" [innerHTML]="ActivityRes.activity?.cRuleContent|html">
    </div>
    <div class="rule__open" (click)="openContent()">展開內容
      <img style="margin-left: 8px;width: 12px;" src="/assets/images/icashpay-icon/arrow-bottom.png" alt="arrow-bottom"></div>
  </div>

  <div class="close" (click)="goto('/')">
    <img src="/assets/images/icashpay-icon/close.svg" alt="">
  </div>
</div>



<app-modal [isModalOpen]="isSigninModalOpen" (modalClose)="isSigninModalOpen=$event">
  <h3 headerContent class="general-modal__title__privacy">
    <img style="width: 97px; height: 56px;" src="/assets/images/icashpay-icon/gift-modal.png" alt="">
  </h3>
  <div bodyContent class="general-modal__content-wrap">
    <div class="general-modal__content-wrap__title">今日已簽到</div>
    <ng-container>
      <div class="general-modal__content-wrap__content">恭喜
        <span style="color: #F68B1F;">{{ActivityInfo.result}}</span>

      </div>
    </ng-container>

  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--cancel" (click)="close()">確認</button>
  </div>
</app-modal>


<div class="bg" *ngIf="isOpen" (click)="openContent()">
  <div class="rule">
    <div class="rule__title">活動規則1</div>
    <div class="rule__content" [innerHTML]="ActivityRes.activity?.cRuleContent|html"></div>
    <div class="rule__open">收合內容
      <img style="margin-left: 8px;width: 12px;transform: rotate(180deg);" src="/assets/images/icashpay-icon/arrow-bottom.png" alt="arrow-bottom">
    </div>
  </div>
</div>
