import { HeaderService } from './shared/service/header.service';
import { HeaderComponent } from './shared/components/header/header.component';
import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import { LoginService } from './shared/service/login.service';
import { environment } from '../environments/environment';
import { HttpService } from './shared/service/http.service';
import { JsonPipe } from '@angular/common';
import { stringify } from 'querystring';
import { LoaderService } from './shared/service/loader.service';
declare var iCashJSContext: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild(HeaderComponent) header: HeaderComponent;
  title = 'ClientAppNew';
  checked = false;
  isChecked = false;
  currentRoute: string;
  isCheckModalOpen = false;
  userToken: string;
  login: any;
  constructor(
    private http: HttpService,
    private router: Router,
    private loaderService: LoaderService,
    private loginService: LoginService,
    public headerService: HeaderService
  ) {
    this.currentRoute = '';
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (environment.production)
          this.getICP168();
        else
          this.testGetICP168();
      }

      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }

      if (event instanceof NavigationError) {
      }
    });
  }

  getICP168() {
    let logins = this.loginService;
    try {
      iCashJSContext.call('getPaymentMID', '', function (data: string) {
        logins.Login(data).subscribe(
          (result) => {
            logins.ResetBonus().subscribe();
          },
          (error) => { }
        );
      });
    } catch (e: any) {
      const mbr168 = window.document.getElementById(
        'mbr168'
      ) as HTMLElement | null;

      if (mbr168) {
        mbr168.innerText = '會員點數' + e.toString();
      }
    }
  }
  testGetICP168() {
    let logins = this.loginService;
    let data = '1682211000006818';
    logins.Login(data).subscribe(
      (result) => {
        logins.ResetBonus().subscribe();
      },
      (error) => { }
    );
  }
}
