import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActivityInfo,
  ActivityReq,
  ActivityRes,
} from 'src/app/shared/model/activityaward';
import { MbrInfo } from 'src/app/shared/model/member';
import { HeaderService } from 'src/app/shared/service/header.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-activity-lottery',
  templateUrl: './activity-lottery.component.html',
  styleUrls: ['./activity-lottery.component.scss'],
})
export class ActivityLotteryComponent implements OnInit {
  isOpen = false;
  isChange = true;
  isSignin = true;
  isLottery = false;
  isSigninModalOpen = false;
  isSigninModalOpenBuy = false;
  buy = false;
  userInfo: MbrInfo;
  ActivetyReq: ActivityReq = new ActivityReq();
  ActivityRes: ActivityRes = new ActivityRes();
  ActivityInfo: ActivityInfo = new ActivityInfo();
  ActivityBuyRes: ActivityRes = new ActivityRes();
  msg: string = '';
  firstmsg: '';
  NoDrawNumber = false;
  isGetDraw = false;
  signinList = [
    {
      day: 1,
      point: 10.01,
      isSignin: true,
    },
    {
      day: 2,
      point: 10.01,
      isSignin: true,
    },
    {
      day: 3,
      point: 10.01,
      isSignin: false,
    },
    {
      day: 4,
      point: 10.01,
      isSignin: false,
    },
    {
      day: 5,
      point: 10.01,
      isSignin: false,
    },
    {
      day: 6,
      point: 10.01,
      isSignin: false,
    },
    {
      day: 7,
      point: 10.01,
      isSignin: false,
    },
  ];

  constructor(
    public headerService: HeaderService,
    private router: Router,
    private loginService: LoginService,
    private httpService: HttpService
  ) {}

  ngOnInit(): void {
    this.headerService.Footer = false;
    this.headerService.Global = false;
    this.headerService.lottery = true;
    this.loginService.userInfo.subscribe((userInfo) => {
      this.userInfo = userInfo;
      this.ActivetyReq.MbrToken = this.userInfo?.Token;
      if (this.ActivetyReq.MbrToken) {
        this.getActivityList();
      }
    });
  }
  ngOnDestroy(): void {
    this.headerService.lottery = false;
    this.headerService.Footer = true;
    this.headerService.Global = true;
  }
  openContent() {
    this.isOpen = !this.isOpen;
  }

  goto(url: string) {
    this.router.navigate([url], { queryParams: { type: 'lottery' } });
  }

  Golottery() {
    if (this.ActivityRes.memberInfo?.cDrawNumber == 0) {
      this.NoDrawNumber = true;
      this.msg = '您目前無抽獎次數';
      this.isSigninModalOpenBuy = true;
      return;
    } else {
      this.NoDrawNumber = false;
    }
    this.isLottery = true;
    // 4.5秒後執行
    this.GetActivity();
    setTimeout(() => {
      this.isSigninModalOpen = true;
      this.isLottery = false;
    }, 4500);
  }

  change() {
    this.isChange = !this.isChange;
  }

  close() {
    this.isSigninModalOpen = false;
    this.isSigninModalOpenBuy = false;
    this.buy = false;
    // location.reload();
  }

  signin() {
    this.isSignin = false;
  }

  getActivityList() {
    if(!this.isGetDraw){
      this.isGetDraw = true;
      this.httpService
        .get('/Activity/GetDrawActivity?mbrToken=' + this.ActivetyReq.MbrToken)
        .subscribe((x: any) => {
          console.log(x);
          this.ActivityRes = x.result;
          this.firstmsg = x.result?.msg;
          if (this.firstmsg) {
            this.isSigninModalOpenBuy = true;
          }
          console.log(this.ActivityRes);
          this.isGetDraw = false;
        });
    }
  }

  GetActivity() {
    this.httpService
      .post('/Activity/Draw?mbrToken=' + this.ActivetyReq.MbrToken, '')
      .subscribe((x: any) => {
        if (x.code == 1) {
          this.ActivityInfo.result = x.result;
          this.getActivityList();
        }
        // this.ListRes = res;
        // this.totalDataCount = res.allDataCount;
      });
  }

  GetBuyNumber() {
    this.buy = true;
    this.httpService
      .post('/Activity/BuyDrawNumber?mbrToken=' + this.ActivetyReq.MbrToken, '')
      .subscribe((x: any) => {
        if (x.code == 1) {
          this.msg = x.result?.msg;
          console.log(this.buy);
          this.isSigninModalOpenBuy = true;
          this.getActivityList();
        }
        // this.ListRes = res;
        // this.totalDataCount = res.allDataCount;
      });
  }
}
