import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Product,
  ProductType,
  RelatedNews,
} from 'src/app/shared/model/product';
import { HeaderService } from 'src/app/shared/service/header.service';
import {
  MbrInfo,
  OrderDetailRes,
  ReturnProductReq,
} from '../../../../shared/model/member';
import { BreadcrumbService } from '../../../../shared/service/breadcrumb.service';
import { HttpService } from '../../../../shared/service/http.service';
import { LoaderService } from '../../../../shared/service/loader.service';
import { LoginService } from '../../../../shared/service/login.service';
import { PagetagService } from '../../../../shared/service/pagetag.service';

@Component({
  selector: 'app-member-order-detail',
  templateUrl: './member-order-detail.component.html',
  styleUrls: ['./member-order-detail.component.scss'],
})
export class MemberOrderDetailComponent implements OnInit {
  // id = '';
  orderMainNo = '';
  product: Product;
  productID: number;
  TicktOpen = false;

  constructor(
    private httpService: HttpService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private loaderService: LoaderService,
    private loginService: LoginService,
    private pagetagService: PagetagService,
    private router: Router,
    public headerService: HeaderService,
    private breadcrumbServie: BreadcrumbService
  ) { }

  productTypeName = '';
  // deliveryFinalStepName = '包裹抵達/票券已至您的票夾';
  deliveryFinalStepName = '訂單失敗';

  detailInfo: OrderDetailRes = new OrderDetailRes();
  itemStatusName: string;
  userInfo: MbrInfo;

  //退貨
  returnProductReq = new ReturnProductReq();
  otherRemark: string;
  //sn
  sn: string;
  relatedNews: RelatedNews;
  isInfoShow = false;
  isTicketProductType = false;
  //modal
  isModalOpen = false;
  modalStepCompleted = false;
  modalMessage: string;
  // timer: any;
  // timerLoading: any;
  goHomePage = false;
  isCloseBtnShow = true;
  modalTiTle = '';
  modalMsg = '';

  ngOnInit(): void {
    this.headerService.Order = false;
    this.headerService.Global = true;
    this.userInfo = this.loginService.GetLocalInfo();
    this.orderMainNo = this.route.snapshot.params['orderMainNo'];
    let tradeResultCode = this.route.snapshot.queryParams['TradeResultCode'];
    let tradeResultMsg = this.route.snapshot.queryParams['TradeResultMsg'];
    let encData = this.route.snapshot.queryParams['EncData'];
    // if (tradeResultCode && tradeResultMsg && encData) {
    //   let req: PaymentSuccessReq = new PaymentSuccessReq();
    //   req.TradeResultCode = tradeResultCode;
    //   req.TradeResultMsg = tradeResultMsg;
    //   req.EncData = encData;
    //   this.httpService
    //     .post('/iCashPay/PaymentSuccess', req)
    //     .subscribe((x: any) => {
    //       if (x.code == 1) {
    //         location.href = '/member/order/detail/' + this.orderMainNo;
    //       } else {
    //         alert(x.result);
    //       }
    //     });
    // } else {
    this.breadcrumbService.breadcrumbData.next([
      { breadcrumb: this.orderMainNo, path: '' },
    ]);

    this.getOrderDetail(this.orderMainNo);
    // }
  }
  ngOnDestroy(): void {
    this.headerService.Global = true;
  }

  getOrderDetail(orderMainNo: string) {
    this.httpService
      .get(
        `/member/GetOrderDetail?mainOrderNo=${orderMainNo}&mbrToken=${this.userInfo.Token}`
      )
      .subscribe((x: any) => {
        this.detailInfo = x;
      });
  }

  // getOrderDetail(MainOrderNo: string) {
  //   this.loaderService.start();
  //   this.httpService
  //     .authGet('/Member/GetOrderDetail?mainOrderNo=' + MainOrderNo)
  //     .subscribe((x: any) => {
  //       this.detailInfo = x;

  //       // this.detailInfo.details=[...this.detailInfo.details].concat([...this.detailInfo.details])
  //       if (!this.detailInfo.isHasDelivery) {
  //         this.detailInfo.shippingDT = x.orderDT;
  //         this.detailInfo.completedDT = x.orderDT;
  //       }
  //       this.productTypeName =
  //         this.detailInfo.details.length > 0
  //           ? this.detailInfo.details[0].productType
  //           : '';

  //       switch (x.orderStatus) {
  //         case 0:
  //           this.itemStatusName = '收到訂單';
  //           break;
  //         case 1:
  //           this.itemStatusName = '訂單處理中';
  //           break;
  //         case 2: {
  //           this.loaderService.stop();
  //           // if (this.productTypeName.includes('票券')) {
  //           // clearInterval(this.timerLoading);
  //           // clearInterval(this.timer);
  //           this.itemStatusName = '交易成功';
  //           this.deliveryFinalStepName =
  //             this.productTypeName.length > 0
  //               ? this.productTypeName.includes('票券')
  //                 ? '交易成功'
  //                 : this.productTypeName.includes('宅配')
  //                   ? '包裹抵達'
  //                   : '包裹抵達/票券已至您的票夾'
  //               : '包裹抵達/票券已至您的票夾';
  //           // }

  //           break;
  //         }
  //         case 3:
  //         case 5:
  //         case 6:
  //           // clearInterval(this.timerLoading);
  //           // clearInterval(this.timer);
  //           this.itemStatusName = this.deliveryFinalStepName;
  //           this.loaderService.stop();
  //           break;
  //         case 4:
  //           // clearInterval(this.timerLoading);
  //           // clearInterval(this.timer);
  //           this.itemStatusName = '訂單失敗';
  //           this.loaderService.stop();
  //           break;
  //         // case 5: this.itemStatusName = "申請退貨中"; break;
  //         // case 6: this.itemStatusName = "退貨完成"; break;
  //         case 7:
  //           // clearInterval(this.timerLoading);
  //           // clearInterval(this.timer);
  //           this.itemStatusName = '其他';
  //           this.loaderService.stop();
  //           break;
  //       }
  //       if (
  //         !this.deliveryFinalStepName.indexOf('抵達') &&
  //         !this.deliveryFinalStepName.indexOf('票夾')
  //       ) {
  //         this.deliveryFinalStepName = this.itemStatusName;
  //       }
  //       // switch (x.itemStatus) {
  //       //   case 0: this.itemStatusName = "收到訂單"; break;
  //       //   case 1: this.itemStatusName = "訂單處理中"; break;
  //       //   case 2:{
  //       //     if(this.productTypeName.includes('票券'))
  //       //     this.itemStatusName = "交易完成";
  //       //      break;
  //       //   }
  //       //   case 3: case 5: case 6: this.itemStatusName = this.deliveryFinalStepName; break;
  //       //   case 4: this.itemStatusName = "訂單取消"; break;
  //       //   // case 5: this.itemStatusName = "申請退貨中"; break;
  //       //   // case 6: this.itemStatusName = "退貨完成"; break;
  //       //   case 7: this.itemStatusName = "其他"; break;
  //       // }
  //     });
  // }
  openReturnProductModal(sn: string) {
    this.modalStepCompleted = false;
    this.returnProductReq.SN = sn;
    this.otherRemark = '';
    this.returnProductReq.Remark = '';
    this.isModalOpen = true;
  }
  returnProduct() {
    if (!this.returnProductReq.Remark) {
      this.modalStepCompleted = true;
      this.modalMessage = '請選擇申請退貨原因';
      return;
    }
    if (this.returnProductReq.Remark == 'other') {
      if (this.otherRemark) {
        this.returnProductReq.Remark = this.otherRemark;
      } else {
        this.modalStepCompleted = true;
        this.modalMessage = '請輸入其他原因';
        return;
      }
    }
    this.isModalOpen = false;
    this.loaderService.start();
    this.httpService
      .post('/Member/ReturnOrder', this.returnProductReq)
      .subscribe((x: any) => {
        this.modalStepCompleted = true;
        this.isModalOpen = true;
        this.modalMessage = x.result;
        let itemNo = this.route.snapshot.params['id'];
        // this.getOrderDetail(itemNo);
        this.loaderService.stop();
      });
  }

  callPagetagService(orderID: string) {
    this.pagetagService.callPageTag(
      this.router.url,
      `會員服務 | 訂單查詢 | ${orderID}`
    );
  }

  getProduct() {
    this.loaderService.start();
    const req: Product = new Product();
    req.id = this.productID;
    this.httpService.post('/Product', req).subscribe((x: any) => {
      //+ "?memberId=" + this.userInfo.ID

      if (x.code != 1) {
        this.goHomePage = true;
        this.isCloseBtnShow = false;
        this.modalTiTle = '提示訊息';
        this.modalMsg = x.result;
        this.isModalOpen = true;
      }

      this.product = x.result;
      // this.limitMsg = this.product.exchangeTypes[0].limitPointMsg;
      // this.isCollect = this.product.exchangeTypes[0].isCollect;
      this.sn = this.product.exchangeTypes[0].sn;
      this.relatedNews = this.product.relatedNews;
      this.isInfoShow = !this.relatedNews ? false : true;
      this.isTicketProductType = x.result.productType == ProductType.電子票券;

      // 手機swiper 每兩個為一組
      let products: any[] = [];
      for (let i = 0; i < this.product.recommendProducts.length; i += 2) {
        let item1 = this.product.recommendProducts[i];
        let item2 = this.product.recommendProducts[i + 1];
        if (!item2) {
          products.push([item1]);
        } else {
          products.push([item1, item2]);
        }
      }
      this.product.productLists = products;

      this.breadcrumbServie.breadcrumbData.next([
        {
          breadcrumb: this.product.categoryName1,
          path: '/product/list/' + this.product.category1,
        },
        {
          breadcrumb: this.product.categoryName2,
          path:
            '/product/list/' +
            this.product.category1 +
            '/' +
            this.product.category2,
        },
        { breadcrumb: this.product.name, path: '' },
      ]);

      this.callPagetagService(
        `品牌館 | ${this.product.categoryName1} | ${this.product.categoryName2} | ${this.product.name}`
      );
      this.loaderService.stop();
    });
  }

  goRecommendProductLink(id: any) {
    this.router.navigateByUrl('/product/detail/' + id);
  }
}
class PaymentSuccessReq {
  TradeResultCode: any;
  TradeResultMsg: any;
  EncData: any;
}
