<!-- 密碼驗證 -->
<!-- <section class="login" *ngIf="modalProcess==0">
  <form #form="ngForm">
    <div class="login__content">
      <div class="login__wrap">
        <div class="login__input-wrap">
          <label class="login__label" for="">密碼</label>
          <input class="login__input" type="text" placeholder="請輸入您的驗證密碼" [(ngModel)]="key"
            [ngModelOptions]="{standalone: true}">
        </div>
        <div class="login__recaptcha-wrap">
          <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required
            [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"
            (resolved)="isResolved=true">
          </re-captcha>
        </div>
        <div class="login__button-wrap">
          <a [routerLink]="['/']" class="login__button login__button--cancel">取消</a>
          <button class="login__button login__button--login" [ngClass]="{'login__button--disabled': !isResolved}"
            (click)="getTicket()">驗證</button>
        </div>
        <div class="login__note-wrap">
          <p class="login__note">
            <span class="note__icon-wrap">
              <img class="note__icon" src="/assets/images/icon/shock_mark_white.svg" alt="">
            </span>
            <span class="note__text">
              若有疑問請洽客服人員
            </span>
          </p>
        </div>
      </div>
    </div>
  </form>
</section> -->
<!-- 票券顯示 / modalProcess 1 -->
<section class="ticket">
  <h4 *ngIf="modalProcess==1" headerContent class="general-modal__title">{{modalDataForUseTicket.name}}</h4>
  <div *ngIf="modalProcess==1" bodyContent class="general-modal__body">
    <div class="ticket-use-modal__detail">
      <div class="ticket-use-modal__image-wrap">
        <img class="ticket-use-modal__image" src="{{modalDataForUseTicket.imgUrl}}" alt="">
      </div>
      <div class="ticket-use-modal__info-wrap">
        <p class="ticket-use-modal__text">
          票劵效期：{{modalDataForUseTicket.expiredDate}}
        </p>
        <p class="ticket-use-modal__text">
          票券序號：{{modalDataForUseTicket.sn}}
        </p>
        <p class="ticket-use-modal__text" *ngIf="!isGift">
          購買日期：{{modalDataForUseTicket.purchaseDate}}
        </p>
        <p class="ticket-use-modal__text" *ngIf="!isGift">
          兌換方式：{{modalDataForUseTicket.exchangeWay}}
        </p>
      </div>
    </div>
    <div lass="ticket-use-modal__bar-code-wrap">
      <ngx-barcode6
        style="max-width: 100%;margin-bottom:12px;display: flex;justify-content: center;align-items: center;"
        *ngIf="modalDataForUseTicket.isQR ==0" [bc-value]="modalDataForUseTicket.sn" [bc-display-value]="true">
      </ngx-barcode6>
      <ngx-qrcode *ngIf="modalDataForUseTicket.isQR ==1"  [scale]="8" [value]="modalDataForUseTicket.qRcodeString">
      </ngx-qrcode>
      <span *ngIf="modalDataForUseTicket.isQR ==1">{{modalDataForUseTicket.couponNo}}</span>
      <p *ngIf="modalDataForUseTicket.isQR ==3">PIN碼:{{modalDataForUseTicket.pinCode}}</p>
      <ngx-barcode6
        style="max-width: 100%;margin-bottom:12px;display: flex;justify-content: center;align-items: center;"
        *ngIf="modalDataForUseTicket.isQR ==2 || modalDataForUseTicket.isQR ==3"
        [bc-value]="modalDataForUseTicket.barcode1" [bc-display-value]="true"></ngx-barcode6>
      <ngx-barcode6
        style="max-width: 100%;margin-bottom:12px;display: flex;justify-content: center;align-items: center;"
        *ngIf="modalDataForUseTicket.isQR ==2" [bc-value]="modalDataForUseTicket.barcode2" [bc-display-value]="true">
      </ngx-barcode6>
      <ngx-barcode6
        style="max-width: 100%;margin-bottom:12px;display: flex;justify-content: center;align-items: center;"
        *ngIf="modalDataForUseTicket.isQR ==4" [bc-value]="modalDataForUseTicket.multiBarcodeType"
        [bc-display-value]="true">
      </ngx-barcode6>
      <ngx-barcode6
        style="max-width: 100%;margin-bottom:12px;display: flex;justify-content: center;align-items: center;"
        *ngIf="modalDataForUseTicket.isQR ==4" [bc-value]="modalDataForUseTicket.couponNo" [bc-display-value]="true">
      </ngx-barcode6>
    </div>
    <div class="ticket-use-modal__notice-wrap">
      <h5 class="ticket-use-modal__notice-title">使用電子票券注意事項</h5>
      <div style="font-size: 16px;
      text-align: justify;
      margin: 0 15px;" [innerHTML]="modalDataForUseTicket.ticketPrecautions | html"></div>
    </div>
  </div>
</section>
<!-- alertModal -->
<app-modal [isModalOpen]="isMsgModalOpen" (modalClose)="isMsgModalOpen=$event">
  <h3 headerContent class="general-modal__title">提示信息</h3>
  <div bodyContent class="general-modal__text-wrap">
    <p class="general-modal__text" *ngFor="let row of Msg.split('br')">{{row}}</p>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="Confirm()">確認</button>
  </div>
</app-modal>
