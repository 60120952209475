export class MbrInfo {
  IsLogin = false;
  ID: string;
  Token: string;
  Name: string;
  Bonus: number;
  CardBonus: number;
  ClearVBonus: number;
  ClearVBonusDate: Date;
  ClearCardBonus: number;
  ClearCardDate: Date;
  Company: string;
  ExpireDate: Date;
  IsEmp = false; //是否為代客兌換的專員
  EmpNo: string; //專員ID
}

export class Ticket {
  img: string;
  title: string;
  ticketNo: string;
  useLimitDT: string;
  exchangeType: string;
  buyDT: string;
  ticketType: number; //1:可使用 2:已使用或過期 3:已轉贈
  couponType: number; // 序號券1 優惠券2 喜客券3
  isQR?: number;
  pinCode: string;
  barcode1: string;
  barcode2: string;
  exchangeDT: string;
  ticketPrecautions: string;
  couponNo: string;
  multiBarcodeType: string;
  qRcodeString: string;
  ticketLink: string;
  snKey: string;
  barCodeType: number;
}

export class OrderListReq {
  MbrID: string;
  MbrToken: string;
  TimeRange: number = 12;
  PageNum: number = 1;
  PageCount: number = 100000;
}

export class OrderMainListRes {
  orderMainNo: string;
  transDT: string;
  prodCount: number;
  points: number;
  prices: number;
  canCancel: boolean;
  canReturn: boolean;
  prodName: string;
  prodType: string;
  orderStatusName: string;
}
export class OrderItemListRes {
  itemNo: string;
  name: string;
  productType: string;
  itemCount: number;
  points: number;
  prices: number;
  canReturn: boolean;
}

export class OrderDetailRes {
  allVBonus: number;
  allCardBonus: number;
  priceType: number;
  allPrice: number;
  orderDT: string;
  shippingDT: string;
  completedDT: string;
  invoiceType: string;
  invoiceNo: string;
  isHasDelivery: boolean;
  recipent: string;
  tel: string;
  address: string;
  deliveryNo: string;
  deliveryLink: string;
  itemStatus: number;
  orderStatusName: string;
  details: OrderDetailRow[] = [];
  orderNo: string;
}
class OrderDetailRow {
  img: string;
  orderNo: string;
  name: string;
  productType: string;
  payType: number;
  cardBonus: number;
  vBonus: number;
  prices: number;
  orderStatusName: string;
  goldFlowStatus: string;
  pointStatus: string;
  deliveryStatusName: string;
  cDeliveryStatus?: number;
  sn: string;
  canReturn: boolean;
  useLimitDate: Date;
  expiration: string;
}

export class CancelOrderReq {
  BUKey: string;
  OrderNumber: string;
  Remark: string;
}
export class ReturnProductReq {
  BUKey: string;
  SN: string;
  Remark: string;
  ItemNo: string;
}

export class VoucherExchangeReq {
  id: string;
  voucher: string;
  token: string | undefined;
}

export class VoucherExchangeRes {
  result: boolean;
  resultMsg: string;
  vBonus: number;
}

export class PointHistoryReq {
  memberID: string;
  dateRange: number;
  historyType: number;
  itemsPerPage: number;
  currentPage: number;
}

class PointHistoryProd {
  prodName: string;
  paid: string;
  bonusChange: number;
  company: string;
}

export class PointHistoryDetail {
  dateTime: Date;
  orderNumber: string;
  source: string;
  bonusExpiryDt?: Date;
  totalBonusChange: number;
  vBonus: number;
  cardBonus: number;
  prods: PointHistoryProd[];
}

export class PointHistoryRes {
  totalDataCount: number;
  details: PointHistoryDetail[];
}

export enum PointHistoryDateRange {
  all = '0',
  oneMonth = '1',
  threeMonth = '2',
}

export enum PointHistoryHistoryType {
  all = '0',
  getPoint = '1',
  usePoint = '2',
}
