import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { MemberCartComponent } from './views/member/member-cart/member-cart.component';
import { MemberOrderComponent } from './views/member/member-order/member-order.component';
import { ProductComponent } from './views/product/product.component';
import { ProductDetailComponent } from './views/product/product-detail/product-detail.component';
import { ProductListComponent } from './views/product/product-list/product-list.component';
import { ProductOverviewComponent } from './views/product/product-overview/product-overview.component';
import { MemberComponent } from './views/member/member.component';
import { MemberVoucherComponent } from './views/member/member-voucher/member-voucher.component';
import { MemberFavoriteComponent } from './views/member/member-favorite/member-favorite.component';
import { MemberTicketComponent } from './views/member/member-ticket/member-ticket.component';
import { MemberPointComponent } from './views/member/member-point/member-point.component';
import { EventComponent } from './views/event/event.component';
import { EventListComponent } from './views/event/event-list/event-list.component';
import { EventDetailComponent } from './views/event/event-detail/event-detail.component';
import { MemberCartResultComponent } from './views/member/member-cart/member-cart-result/member-cart-result.component';
import { HomeLoginComponent } from './views/home/home-login/home-login.component';
import { HomeRegisterComponent } from './views/home/home-register/home-register.component';
import { MemberOrderDetailComponent } from './views/member/member-order/member-order-detail/member-order-detail.component';
import { QuestionComponent } from './views/question/question.component';
import { QuestionContactComponent } from './views/question/question-contact/question-contact.component';
import { QuestionListComponent } from './views/question/question-list/question-list.component';
import { MemberPointTransferComponent } from './views/member/member-point/member-point-transfer/member-point-transfer.component';
import { AuthGuard } from './shared/guards/auth-guard';
import { HomeSitemapComponent } from './views/home/home-sitemap/home-sitemap.component';
import { ShutdownGuard } from './shared/guards/shutdown-guard';
import { HomeShutdownComponent } from './views/home/home-shutdown/home-shutdown.component';
import { TicketLinkComponent } from './views/ticket-link/ticket-link.component';
import { ColophonComponent } from './shared/components/colophon/colophon.component';
import { TremsComponent } from './shared/components/trems/trems.component';
import { PrivacyComponent } from './shared/components/privacy/privacy.component';
import { MemberTicketDetailComponent } from './views/member/member-ticket-detail/member-ticket-detail.component';
import { ActivityComponent } from './views/activity/activity.component';
import { ActivitySigninComponent } from './views/activity/activity-signin/activity-signin.component';
import { ActivityLogComponent } from './views/activity/activity-log/activity-log.component';
import { ActivityLotteryComponent } from './views/activity/activity-lottery/activity-lottery.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [ShutdownGuard],
  },
  {
    path: 'login',
    component: HomeLoginComponent,
    canActivate: [ShutdownGuard],
    data: {
      breadcrumb: '會員登入',
      path: '/login',
    },
  },
  {
    path: 'register',
    component: HomeRegisterComponent,
    canActivate: [ShutdownGuard],
    data: {
      breadcrumb: '加入會員',
      path: '/register',
    },
  },
  {
    path: 'sitemap',
    component: HomeSitemapComponent,
    canActivate: [ShutdownGuard],
    data: {
      breadcrumb: '網站地圖',
      path: '/sitemap',
    },
  },
  {
    path: 'shutdown',
    component: HomeShutdownComponent,
    canActivate: [ShutdownGuard],
    data: {
      breadcrumb: '停機公告',
      path: '/shutdown',
    },
  },
  {
    path: 'product',
    component: ProductComponent,
    data: {
      breadcrumb: '品牌館',
      path: '/product/overview',
    },
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
      },
      {
        // 品牌或館別總覽頁
        path: 'overview',
        component: ProductOverviewComponent,
        canActivate: [ShutdownGuard],
      },
      {
        // 品牌或館別列表頁(子品牌)
        path: 'list/:cat1/:cat2',
        component: ProductListComponent,
        canActivate: [ShutdownGuard],
      },
      {
        // 品牌或館別列表頁(總品牌)
        path: 'list/:cat1',
        component: ProductListComponent,
        canActivate: [ShutdownGuard],
      },
      {
        // 品牌或館別列表頁(總品牌)
        path: 'recommend',
        component: ProductListComponent,
        canActivate: [ShutdownGuard],
        data: {
          breadcrumb: '推薦商品列表',
          path: '/product/list',
        },
      },
      {
        //首頁類別
        path: 'recommend/:type',
        component: ProductListComponent,
        canActivate: [ShutdownGuard],
        data: {
          breadcrumb: '推薦商品列表',
          path: '/product/list',
        },
      },
      {
        // 商品詳細頁
        path: 'detail/:id',
        component: ProductDetailComponent,
        canActivate: [ShutdownGuard],
      },
    ],
  },
  {
    path: 'search',
    component: ProductComponent,
    data: {
      breadcrumb: '全館搜索',
      path: '/search/:pName',
    },
    children: [ {
      // 品牌或館別總覽頁
      path: ':pName',
      component: ProductListComponent,
      canActivate: [ShutdownGuard],
    },]


  },
  {
    path: 'event',
    component: EventComponent,
    children: [
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: ':id',
        component: EventListComponent,
        canActivate: [ShutdownGuard],
      },
      {
        path: ':cat/:id',
        component: EventDetailComponent,
        canActivate: [ShutdownGuard],
      },
    ],
  },
  {
    path: 'member',
    component: MemberComponent,
    // canActivate: [AuthGuard],
    data: {
      breadcrumb: '會員服務',
      path: '/member/cart',
    },
    children: [
      {
        path: '',
        redirectTo: 'cart',
        pathMatch: 'full',
      },
      {
        path: 'cart',
        component: MemberCartComponent,
        canActivate: [ShutdownGuard],
        pathMatch: 'full',
        data: {
          breadcrumb: '購物車',
          path: '/member/cart',
        },
      },
      {
        path: 'cart/result',
        component: MemberCartResultComponent,
        canActivate: [ShutdownGuard],
        data: {
          breadcrumb: '購物車',
          path: '/member/cart',
        },
      },
      {
        path: 'order',
        component: MemberOrderComponent,
        canActivate: [ShutdownGuard],
        pathMatch: 'full',
        data: {
          breadcrumb: '訂單查詢',
          path: '/member/order',
        },
      },
      {
        path: 'order/detail/:orderMainNo',
        component: MemberOrderDetailComponent,
        canActivate: [ShutdownGuard],
        data: {
          breadcrumb: '訂單查詢',
          path: '/member/order',
        },
      },
      {
        path: 'point',
        component: MemberPointComponent,
        canActivate: [ShutdownGuard],
        pathMatch: 'full',
        data: {
          breadcrumb: '會員點數',
          path: '/member/point',
        },
      },
      {
        path: 'point/transfer',
        component: MemberPointTransferComponent,
        canActivate: [ShutdownGuard],
        data: {
          breadcrumb: '會員點數',
          path: '/member/point',
        },
      },
      {
        path: 'ticket',
        component: MemberTicketComponent,
        canActivate: [ShutdownGuard],
        data: {
          breadcrumb: '會員票夾',
          path: '/member/ticket',
        },
      },
      {
        path: 'ticket/detail/:a',
        component: MemberTicketDetailComponent,
        canActivate: [ShutdownGuard],
        data: {
          breadcrumb: '會員票夾明細',
          path: '/member/ticket',
        },
      },
      {
        path: 'favorite',
        component: MemberFavoriteComponent,
        canActivate: [ShutdownGuard],
        data: {
          breadcrumb: '我的收藏',
          path: '/member/favorite',
        },
      },
      {
        path: 'voucher',
        component: MemberVoucherComponent,
        canActivate: [ShutdownGuard],
        data: {
          breadcrumb: '優惠碼',
          path: '/member/voucher',
        },
      },
    ],
  },
  {
    path: 'question',
    component: QuestionComponent,
    children: [
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'contact',
        component: QuestionContactComponent,
        canActivate: [ShutdownGuard],
        pathMatch: 'full',
        data: {
          breadcrumb: '聯絡我們',
          path: '/question/contact',
        },
      },
      {
        // 由訂單導至聯絡我們
        path: 'contact/:orderNumber',
        component: QuestionContactComponent,
        canActivate: [ShutdownGuard],
      },
      {
        path: ':id',
        component: QuestionListComponent,
        canActivate: [ShutdownGuard],
      },
    ],
  },
  {
    path: 'activity',
    component: ActivityComponent,
    data: {
      breadcrumb: '活動',
      path: '/activity/signin',
    },
    children:[
      {
        path: '',
        redirectTo: 'signin',
        pathMatch: 'full',
      },
      {
        path: 'signin',
        component: ActivitySigninComponent,
        canActivate: [ShutdownGuard],
      },
      {
        path: 'log',
        component: ActivityLogComponent,
        canActivate: [ShutdownGuard]
      },
      {
        path: 'lottery',
        component: ActivityLotteryComponent,
        canActivate: [ShutdownGuard]
      }

    ]
  },
  {
    path: 'Colophon',
    component: ColophonComponent,
  },
  {
    path: 'Privacy',
    component: PrivacyComponent,
  },
  {
    path: 'Trems',
    component: TremsComponent,
  },
  {
    path: 'ticket-link',
    component: TicketLinkComponent,
  },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
