<div class="container">
  <div class="container">
    <h2 class="contact-title">隱私權條款</h2>
    <h3 class="contact-title">喜客券商城(以下稱本平台)使用者個人資料暨隱私權保護政策，請參閱以下條款：</h3>
    <ol class="contact-note">
      <li>本聲明適用於icash Pay會員藉由電腦或行動裝置連結登入喜客券商城時，即適用本聲明。本聲明不適用於本平台以外之相關網站連結，倘您點選該連結，您必須參考該連結網站中的隱私權保護政策。
      </li>
      <li>
        個人資料之蒐集聲明愛金卡商城係由「精誠資訊股份有限公司」(以下稱精誠資訊或本公司)所維運。本平台向您聲明，當您以愛金卡會員身分登入愛金卡商城時，本平台之愛金卡會員身分驗證係由愛金卡股份有限公司之愛金卡產品協助驗證，本平台並不保留身分證字號等信息。
        除此之外，我們也會保留您在上網瀏覽或查詢時，伺服器自行產生的相關紀錄，包括但不限於您使用連線設備的IP位址、使用的瀏覽器、使用時間、瀏覽及點選紀錄等。
      </li>
      <li>
        本聲明蒐集資料之運用聲明本平台所蒐集的個人資料，將依蒐集時所告知您之特定目的使用，您於線上商品兌換服務中所提供的個人資訊將會由負責該商品服務的相關單位處理，以便與您聯繫。針對各類線上商品兌換，您所提供的個人資料，將在取得您明確同意的前提下，視蒐集目的分別做為答覆問題、客服通知，票券提供、或是商業及市場分析之用。個人資料之使用目的、利用對象、時間等資訊請參考本平台之內容，從本平台上獲得的資料將只使用在與資料蒐集時所告知您的特定目的上。除非在蒐集時有向您告知並徵得您的同意，否則本平台所蒐集的個人資料，將不會與他人共同使用、亦不會使用在未告知您的用途上。
      </li>
      <li>Cookie
        技術之使用為了便利使用者，本平台可能使用cookie技術，以便於提供更適合會員個人需要的服務；cookie是本平台伺服器用來和使用者瀏覽器進行溝通的一種技術，它可能在使用者的電腦中儲存某些資訊，本平台也會讀取儲存在使用者電腦中的cookie資料。會員可以經由瀏覽器的設定，取消、或限制此項功能，但可能因此無法使用部份網站功能。若您想知道如何取消、或限制此項功能，可參照您所使用的瀏覽器關於如何管理cookie的相關說明。
      </li>
      <li>本平台與第三者共用個資之聲明本平台絕不會洩露或轉售您的個人資料給其他與您所參與活動不相關的第三方。惟有下列三種情形，本平台會與第三方共用您的個人資料：
        <ul>
          <li>
            取得您的明確同意或授權允許時。
          </li>
          <li>
            司法單位或其他行政主管機關經合法正式的程序要求時。
          </li>
          <li>
            為了提供您其他服務或優惠權益，需要與提供該服務或優惠之第三人共用您的資料時，本平台會在活動時提供充分說明並告知，您可以自由選擇是否接受這項服務或優惠。 </li>
        </ul>
      </li>
      <li>
        第三方網站連結在本平台中，您將可能點選到第三方網站。該等第三方網站的隱私權保護聲明運作，並非由本平台管理，所以於該等第三方網站中，不適用本平台隱私權保護聲明。建議您在該等第三方網站中欲提供個人資料前，詳閱該等第三方網站的隱私權保護相關聲明。
      </li>
      <li>當事人權利行使除法令另有規定外，您得對本平台所蒐集、處理、利用與保存您的個人資料，要求：

        <ul>
          <li>
            撤回先前有關蒐集、處理或利用之同意；
          </li>
          <li>
            限制處理或利用方式(限歐盟居民及歐盟居民自歐盟境內所提供之個人資料適用)；
          </li>
          <li>
            查詢或閱覽； </li>
          <li>製給複製本；</li>
          <li>將您所提供之個人資料移轉到您所指定的第三方或裝置(限歐盟居民及歐盟居民自歐盟境內所提供之個人資料適用)；</li>
          <li>補充或更正；</li>
          <li>停止蒐集、處理或利用；或刪除。</li>
        </ul>
      </li>
      <li>本平台將依個人資料保護法及相關法令規範，決定是否受理您的申請。
        除法令另有規定外，本平台將於蒐集之特定目的達成或消滅後，依本平台內部規定將您的個人資料銷毀刪除，或將您的個人資料去識別化且後續無法追蹤到您本人的方式留存。
      </li>
      <li  >安全性為了防止您所提供的個人資料遭外洩、遺失、濫用、變更或破壞，本平台將使用通常認可的標準技術以及操作上的防護，來保護您的個人資料，例如網站的傳輸過程使用SSL（Secure
        Socket
        Layer）作為資料傳輸加密保護機制
        。但由於本平台無法確保不會發生未經授權的存取事件，我們建議您不要將您使用本平台的相關帳號密碼分享給任何第三人；如您與他人共用電腦的話，應於離開本平台前先行登出您的帳號，以防止後續使用者得接觸到您於本平台的個人資訊。</li>
      <li  >
        本聲明之修訂由於社會環境及法令規定之變遷或科技技術進步，本平台將盡全力保護您的網路隱私，故本平台有權不定時修訂與公布本項聲明以合時宜。也請您隨時上網參覽本項聲明，以保障您的權益。</li>
      <li>
        會員聯絡資訊本平台為保護使用者個人資料，維護網路隱私權，特訂定本隱私權保護聲明，若您對於本隱私權保護聲明、或與個人資料有關之相關事項有任何疑問，或欲行使上述當事人權利時，可利用本平台”聯絡我們”
        詢問，我們會提供最完整的說明。如您認為本平台有違反法令致影響您的權益時，您有權利向個資保護相關主管機關進行申訴。</li>
      <li class= "contact-note--last">本隱私權聲明最近更新：113年 05月 06日</li>
    </ol>
  </div>
</div>
