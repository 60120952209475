import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/service/header.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  childrenPathObjList: { path: string, name: string }[] = [
    // { path: '21', name: '產品與服務' },
    { path: '13', name: '最新消息' },
    { path: '12', name: '集點秘笈' },
  ];
  constructor(
    public headerService:HeaderService,
  ) { }

  ngOnInit(): void {
    this.headerService.Footer =true;
    this.headerService.Colorless=true;
  }

  ngOnDestroy(): void {
    this.headerService.Colorless=false;
    this.headerService.Footer =false;
  }
}
