import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/shared/service/login.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { ActivityReq, ActivityRes } from 'src/app/shared/model/activityaward';
import { MbrInfo } from 'src/app/shared/model/member';
import { HeaderService } from 'src/app/shared/service/header.service';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {
  lottery = false;
  ActivityRes: ActivityRes = new ActivityRes;
  selectType: number = 1;
  userInfo: MbrInfo;
  ActivetyReq:ActivityReq = new ActivityReq;;
  //
  filterInfos = [
    {
      content: '獲得100點',
      date: '2023-12-29 12:00:00',
      point: '+100點'
    }
  ];

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private httpService: HttpService,
    public headerService: HeaderService,
    ) { }

  ngOnInit(): void {
    this.headerService.Footer = false;
    this.userInfo = this.loginService.GetLocalInfo();
    this.ActivetyReq.MbrToken = this.userInfo.Token;
    this.route.queryParams.subscribe(params => {
      const type = params['type'];
      if (type === 'lottery') {
        this.lottery = true;
        this.getActivityList();
      }
      else{
        this.getSignList();
      }
    });
  }

  changeType(type: number) {
    this.selectType = type;
  }

  goback() {
    this.location.back();
  }

  getActivityList() {
    this.httpService.get('/Activity/GetDrawActivity?mbrToken='+ this.ActivetyReq.MbrToken).subscribe((x: any) => {
      console.log(x)
      this.ActivityRes = x.result;
      console.log(this.ActivityRes)
    });
  }

  getSignList() {
    this.httpService.get('/Activity/GetSignInActivity?mbrToken='+ this.ActivetyReq.MbrToken).subscribe((x: any) => {
      console.log(x)
      this.ActivityRes = x.result;
     this
    });
  }

}
