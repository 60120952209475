import { Brand, Brandlist } from './../../model/product';
import { LoginService } from '../../service/login.service';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { MbrInfo } from '../../model/member';
import { HttpService } from '../../service/http.service';
import { CartService } from '../../service/cart.service';
import { EventListReq, EventListRes } from '../../model/event';
import { QuestionService } from '../../service/question.service';
import { QuestionType } from '../../model/question';
import { CollectionTarget } from '../../model/cart';
import { ShutdownService } from '../../service/shutdown.service';
import { Observable } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { ParentBrandService } from '../../service/parent-brand.service';
import { addThouthandSeparator } from '../../helpers/format-helper';
import { HeaderService } from '../../service/header.service';
import { environment } from 'src/environments/environment.uat';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isSystemShutdown = false;

  isComponentInit = false;

  isMobile: boolean;
  isBurgerActived = false;
  burgerIconUrl = '/assets/images/icon/burger_menu.svg';
  headerLogoUrl = '/assets/images/logo/logo_white.svg';
  isHeaderFixed = false;
  isInfoShow = true;
  cartNumber = 0;
  brandList: Brand[] = [];
  userInfo: MbrInfo;
  keyword = '';
  eventListReqFor21: EventListReq = {
    CType: 21,
    PageNum: 1,
    PageCount: 3,
  };

  eventListReqFor13: EventListReq = {
    CType: 13,
    PageNum: 1,
    PageCount: 3,
  };

  eventListResFor21 = new EventListRes();
  eventListResFor13 = new EventListRes();


  collectionTarget: CollectionTarget;

  isSearch = false;
  isSearch2 = false;
  point: number;

  version = environment.version;
  constructor(
    private elements: ElementRef,
    private router: Router,
    public loginService: LoginService,
    private httpService: HttpService,
    private cartService: CartService,
    private shutdownService: ShutdownService,
    private parentBrandService: ParentBrandService,
    public headerService: HeaderService,
  ) { }

  ngOnInit(): void {
    // this.loginService.$point.subscribe(x => {
    //   this.point = x;
    // });
    this.router.events.subscribe((value) => {
      if (value instanceof NavigationStart) {
        if (value.url.includes('search')) this.isSearch = true;
      }
    });
    if (this.isComponentInit) return;
    this.checkIfShutdown().subscribe((isShutdown) => {
      if (isShutdown) {
        return;
      }
      // 取得購物車數量
      // this.cartService.getCartNumber();
      // 取得品牌館內容
      this.getParentBrandList();
      // 綁定購物車數量至元件屬性
      this.cartService.cartNumber.subscribe((num) => {
        this.cartNumber = num;
      });

      this.isMobile = window.innerWidth > 1200 ? false : true;
      this.getEventList();
      // this.getCollectionTarget();
      this.listenParentBrandList();
    });
    this.isComponentInit = true;
  }
  goSearch() {
    this.router.navigateByUrl('/' + this.keyword);
  }

  checkIfShutdown() {
    return new Observable((obs) => {
      this.shutdownService.isShutdown.subscribe((isShutdown) => {
        this.isSystemShutdown = isShutdown;
        obs.next(isShutdown);
      });
    });
  }

  getEventList() {
    this.httpService
      .post('/tbl_ActivityNews/GetActivityList', this.eventListReqFor21)
      .subscribe((res) => {
        this.eventListResFor21 = res;
      });
    this.httpService
      .post('/tbl_ActivityNews/GetActivityList', this.eventListReqFor13)
      .subscribe((res) => {
        this.eventListResFor13 = res;
      });
  }



  getCollectionTarget() {
    let memberInfo = this.loginService.GetLocalInfo();
    if (!memberInfo.ID) {
      return;
    }

    this.httpService
      .get(
        `api/member/GetCollectionTarget?id=${memberInfo.ID}&bonus=${memberInfo.Bonus + memberInfo.CardBonus
        }`
      )
      .subscribe((x: any) => {
        this.collectionTarget = x;
      });
  }

  goToLink(
    event: any,
    isAngularBased: boolean,
    url: string,
    isTargetBlank = false
  ) {
    this.onRouteChange();
    event.preventDefault();
    if (isTargetBlank) {
      window.open(url, '_blank');
      return;
    }
    if (isAngularBased) {
      this.router.navigate([url]);
      return;
    }
    window.location.href = url;
  }

  logout() {
    this.loginService.Logout();
  }
  @HostListener('window:scroll', ['$event']) onScroll($event: any) {
    const windowWidth = $event.path && $event.path[1].innerWidth;
    const windowY = $event.path && $event.path[1].scrollY;
    this.handleHeaderStyle(windowWidth, windowY);
  }

  @HostListener('window:resize', ['$event']) onResize($event: any) {
    const windowWidth = $event.target.innerWidth;
    const windowY = $event.target.scrollY;
    this.handleHeaderStyle(windowWidth, windowY);

    if (windowWidth < 1200) {
      this.isMobile = true;
      return;
    }

    this.isMobile = false;
    this.resetMenu();
  }

  // window scroll 大於100 時，固定header
  handleHeaderStyle(windowWidth: number, windowY: number) {
    if (windowWidth < 1200) {
      this.isHeaderFixed = false;
      return;
    }

    if (windowY > 100) {
      this.isHeaderFixed = true;
    } else {
      this.isHeaderFixed = false;
    }
  }

  // 手機版點擊漢堡icon
  onBurgerIconClick($event: any) {
    $event.preventDefault();

    this.isBurgerActived = !this.isBurgerActived;
    if (this.isBurgerActived) {
      this.burgerIconUrl = '/assets/images/icon/burger_menu_cross.svg';
      this.headerLogoUrl = '/assets/images/logo/logo_black.svg';
    } else {
      this.burgerIconUrl = '/assets/images/icon/burger_menu.svg';
      this.headerLogoUrl = '/assets/images/logo/logo_white.svg';
    }
  }

  // 手機版點擊選單標題，展開或關閉內容
  onSubMenuCollapse($event: any, menuWrapElement: any, menuListElement: any) {
    $event.preventDefault();
    if (window.innerWidth >= 1200) {
      return;
    }
    if (
      !(
        $event.target.classList.contains('js-main-menu__link') ||
        $event.target.classList.contains('js-main-menu__text') ||
        $event.target.classList.contains(
          'js-main-menu__link-state-icon--shrink'
        ) ||
        $event.target.classList.contains(
          'js-main-menu__link-state-icon--expand'
        )
      )
    ) {
      return;
    }

    let targetLink: HTMLAnchorElement;
    if ($event.target.classList.contains('js-main-menu__link')) {
      targetLink = $event.target;
    } else {
      targetLink = $event.target.parentNode.closest('.js-main-menu__link');
    }

    let menuListHeight = menuListElement.offsetHeight;
    let subMenuWrapElements = this.elements.nativeElement.querySelectorAll(
      '.js-main-menu__sub-menu'
    );
    let mainMenuLinks = this.elements.nativeElement.querySelectorAll(
      '.js-main-menu__link'
    );
    for (let i = 0; i < subMenuWrapElements.length; i++) {
      if (subMenuWrapElements[i] == menuWrapElement) {
        continue;
      }

      subMenuWrapElements[i].style['max-height'] = '0px';
      subMenuWrapElements[i].style['visibility'] = 'hidden';
    }

    for (let j = 0; j < mainMenuLinks.length; j++) {
      mainMenuLinks[j].querySelector(
        '.js-main-menu__link-state-icon--expand'
      ).style.display = 'inline';
      mainMenuLinks[j].querySelector(
        '.js-main-menu__link-state-icon--shrink'
      ).style.display = 'none';

      mainMenuLinks[j].style['color'] = '#1f1f1f';
    }

    if (menuWrapElement.offsetHeight > 0) {
      let targetLinkExpandIcon = targetLink.querySelector(
        '.js-main-menu__link-state-icon--expand'
      ) as HTMLElement;
      let targetLinkShrinkIcon = targetLink.querySelector(
        '.js-main-menu__link-state-icon--shrink'
      ) as HTMLElement;
      targetLinkExpandIcon.style.display = 'inline';
      targetLinkShrinkIcon.style.display = 'none';

      targetLink.style['color'] = '#1f1f1f';
      menuWrapElement.style['max-height'] = '0px';
      menuWrapElement.style['visibility'] = 'hidden';
    } else {
      let targetLinkExpandIcon = targetLink.querySelector(
        '.js-main-menu__link-state-icon--expand'
      ) as HTMLElement;
      let targetLinkShrinkIcon = targetLink.querySelector(
        '.js-main-menu__link-state-icon--shrink'
      ) as HTMLElement;
      targetLinkExpandIcon.style.display = 'none';
      targetLinkShrinkIcon.style.display = 'inline';

      targetLink.style['color'] = '#E7007F';
      menuWrapElement.style['max-height'] = menuListHeight + 'px';
      menuWrapElement.style['visibility'] = 'visible';
    }
  }

  // 手機版點擊選單子連結，關閉漢堡選單
  onRouteChange() {
    this.isBurgerActived = false;
    this.burgerIconUrl = '/assets/images/icon/burger_menu.svg';
    this.headerLogoUrl = '/assets/images/logo/logo_white.svg';

    this.resetMenu();
  }

  // 選單回到預設樣式
  resetMenu() {
    // reset 選單標題
    this.elements.nativeElement
      .querySelectorAll('.main-menu__link')
      .forEach((item: HTMLElement) => {
        item.style['color'] = '#1f1f1f';
        let targetLinkExpandIcon = item.querySelector(
          '.js-main-menu__link-state-icon--expand'
        ) as HTMLElement;
        let targetLinkShrinkIcon = item.querySelector(
          '.js-main-menu__link-state-icon--shrink'
        ) as HTMLElement;
        targetLinkExpandIcon.style.display = 'inline';
        targetLinkShrinkIcon.style.display = 'none';
      });

    // 關閉選單內容區塊
    let subMenuList = this.elements.nativeElement.querySelectorAll(
      '.main-menu__sub-menu'
    );
    for (let i = 0; i < subMenuList.length; i++) {
      subMenuList[i].style['max-height'] = '0px';
      subMenuList[i].style['visibility'] = 'hidden';
    }
  }

  listenParentBrandList() {
    this.parentBrandService.parentBrandList.subscribe((val) => {
      this.brandList = val;
    });
  }

  //取得品牌館內容
  getParentBrandList() {
    this.httpService.get('/Home/GetParentBrandList').subscribe((x: any) => {
      this.brandList = x;
      this.setParentBrandList(this.brandList);
    });
  }

  setParentBrandList(brands: Brand[]) {
    this.parentBrandService.parentBrandList.next(brands);
  }

  addThouthandMark(num: number) {
    return addThouthandSeparator(num);
  }

  searchChange() {
    this.isSearch2 = !this.isSearch2;
  }
}
