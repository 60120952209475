import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { PagetagService } from 'src/app/shared/service/pagetag.service';

@Component({
  selector: 'app-home-login',
  templateUrl: './home-login.component.html',
  styleUrls: ['./home-login.component.scss']
})
export class HomeLoginComponent implements OnInit, OnDestroy {
  isResolved = false;
  token: string | undefined;
  id: string;

  //msgModal
  isMsgModalOpen: boolean = false;
  Msg: string = "";
  toRegister: boolean = false

  constructor(
    private httpService: HttpService,
    private loaderService: LoaderService,
    private pagetagService: PagetagService,
    private router: Router
  ) {
    this.token = undefined;
  }

  ngOnInit(): void {
    this.callPagetagService();
  }

  // 離開登入頁時，移除productQuery // 若是以href 方式導頁並不會觸發
  ngOnDestroy() {
    sessionStorage.removeItem("productQuery");
  }

  public send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }
    this.loaderService.start();
    this.httpService.post("/HNAuth/GetLoginUrl", { ID: this.id, RecaptchaToken: this.token }).subscribe((x: any) => {
      if (x.code == 1) {
        location.href = x.result;
      }
      else {
        switch (x.code) {
          case 0:
            this.toRegister = false; break;
          case 3:
            this.toRegister = true; break;
        }
        this.isMsgModalOpen = true;
        this.Msg = x.result;
        this.loaderService.stop();
      }
    });
  }
  Confirm() {
    if (this.toRegister) {
      location.href = '/register';
    }
    this.isMsgModalOpen = false;
    this.isResolved = false;
  }

  callPagetagService() {
    this.pagetagService.callPageTag(this.router.url, '會員登入')
  }
}
