<div class="product-background">
  <section class="event-detail">
    <div class="event-list__col">
      <div class="bottom">
        <div class="event-detail__title">
          <a *ngIf="eventInfo.cName">{{eventInfo.cName}}</a>
          <div class="event-detail__image">
            <a *ngIf="eventInfo.cUrl" href="{{eventInfo.cUrl}}" target="{{eventInfo.cAnother ? '_blank' : undefined}}">
              <img src="{{eventInfo.cImg}}" alt="" style="width: 100%;">
            </a>
            <a *ngIf="!eventInfo.cUrl" [routerLink]="['/event', eventInfo.cType,eventInfo.id]">
              <img src="{{eventInfo.cImg}}" alt="" style="width: 100%;">
            </a>
          </div>
        </div>
      </div>
  
      <div>
        活動期間:
        <span class="dateorange">{{eventInfo.cSdate | date: 'yyyy/MM/dd'}}~{{eventInfo.cEdate |
          date: 'yyyy/MM/dd'}}</span>
      </div>
      <div>
        活動方式:
        <div class="event-detail__content-wrap" *ngIf="eventInfo.cContent" [innerHtml]="eventInfo.cContent | html">
        </div>
      </div>
      <!-- <div class="event-detail__content">
  
        
      </div> -->
  
    </div>
  </section>
</div>

<div class="process-step-button row">
  <div class="process-step-button__button-wrap">
    <a class="process-step-button__button process-step-button__button--cancel" (click)="goback()">回上一頁</a>
  </div>
</div>
