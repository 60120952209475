import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivityInfo, ActivityReq, ActivityRes } from 'src/app/shared/model/activityaward';
import { MbrInfo } from 'src/app/shared/model/member';
import { HeaderService } from 'src/app/shared/service/header.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { LoginService } from 'src/app/shared/service/login.service';

@Component({
  selector: 'app-activity-signin',
  templateUrl: './activity-signin.component.html',
  styleUrls: ['./activity-signin.component.scss']
})
export class ActivitySigninComponent implements OnInit {

  isOpen = false;
  isChange = true;
  isSignin = true;
  cMode:number;
  isSigninModalOpen = false;
  userInfo: MbrInfo;
  ActivetyReq: ActivityReq = new ActivityReq;
  ActivityRes: ActivityRes = new ActivityRes;
  ActivityInfo:ActivityInfo = new ActivityInfo;
  signinList = [
    {
      day: 1,
      point: 10.01,
      isSignin: true
    },
    {
      day: 2,
      point: 10.01,
      isSignin: true
    },
    {
      day: 3,
      point: 10.01,
      isSignin: false
    },
    {
      day: 4,
      point: 10.01,
      isSignin: false
    },
    {
      day: 5,
      point: 10.01,
      isSignin: false
    },
    {
      day: 6,
      point: 10.01,
      isSignin: false
    },
    {
      day: 7,
      point: 100.01,
      isSignin: false
    },
  ]
  signDay:number;
  hasCalledGetSign: boolean = false;
  constructor(
    public headerService: HeaderService,
    private router: Router,
    private loginService: LoginService,
    private httpService: HttpService,
  ) { }

  ngOnInit(): void {
    this.headerService.Footer = false;
    this.headerService.Global = false;
    this.headerService.lottery = true;
    this.loginService.userInfo.subscribe((userInfo) => {
      this.userInfo = userInfo;
      this.ActivetyReq.MbrToken = this.userInfo?.Token;
      if (this.ActivetyReq.MbrToken) {
        this.getSignList()
      }
    });
  }

  ngOnDestroy(): void {
    this.headerService.lottery = false;
    this.headerService.Footer = true;
    this.headerService.Global = true;
  }
  openContent() {
    this.isOpen = !this.isOpen;
  }

  goto(url: string) {
    this.router.navigate([url]);
  }

  change() {
    this.isChange = !this.isChange;
  }

  signin() {
   this.GetSign();
  }

  close(){
    this.isSigninModalOpen = false;
    // location.reload();
  }

  getSignList() {
    this.httpService.get('/Activity/GetSignInActivity?mbrToken=' + this.ActivetyReq.MbrToken).subscribe((x: any) => {
      console.log(x)
    this.ActivityRes = x.result;

    if(this.ActivityRes.activity != null){
    this.signDay = this.ActivityRes.signInDetail.length;
    this.cMode = this.ActivityRes.activitySignIn?.cMode;
      if (this.cMode == 1 && !this.hasCalledGetSign) {
        this.GetSign();
      }
    }
  });
  }

  GetSign() {
    this.hasCalledGetSign = true;
    this.httpService.post('/Activity/SignIn?mbrToken=' + this.ActivetyReq.MbrToken, '').subscribe((x: any) => {
      if (x.code == 1) {
        this.ActivityInfo = x
        if (!this.ActivityRes.memberInfo?.cIsSignIn) {
          this.isSigninModalOpen = true;
        }
        this.hasCalledGetSign = true;
        this.getSignList();
      }
      console.log(x);
    });
  }
}
