import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { QuestionType } from '../../model/question';
import { HttpService } from '../../service/http.service';
import { LoginService } from '../../service/login.service';
import { QuestionService } from '../../service/question.service';
import { HeaderService } from '../../service/header.service';
import { environment } from 'src/environments/environment.uat';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isHomePage = false;
  isPrivacyModalOpen = false;
  isCopyrightModalOpen= false;
  questionTypeList: QuestionType[] = [];
  isBill = false;
  version = environment.version;

  constructor(private router: Router,
    private httpService: HttpService,
    private loginService: LoginService,
    private questionService: QuestionService,
    public headerService: HeaderService,
  ) { }

  ngOnInit(): void {
    this.checkPathname();
    this.router.events.subscribe(item => {
      this.checkPathname();
    })
    this.getQuestionTypeList();
    this.router.events.subscribe((value) => {
      if (value instanceof NavigationStart) {
        if (value.url.includes('detail')) this.isBill = true;
        if (value.url.includes('cart')) this.isBill = true;
      }
    });
  }

  getQuestionTypeList() {
    this.httpService.get('/tbl_QaType').subscribe((x: any) => {
      this.questionTypeList = x;
      this.questionService.setQuestionTypeList(x);
    });
  }

  checkPathname() {
    const path = window.location.pathname;
    // 首頁
    if (path == "/") {
      this.isHomePage = true;
    } else {
      this.isHomePage = false;
    }
  }

  handlePrivacyLinkClick(e: any) {
    e.preventDefault();
    this.isPrivacyModalOpen = true;
  }

  handleCopyrightLinkClick(e: any) {
    e.preventDefault();
    this.isCopyrightModalOpen = true;
  }
}
