export class QuestionListRes {
  cContent: string;
  cCreateDt: string;
  cCreator: string;
  cEdate: string;
  cQatypeId: number;
  cSdate: string;
  cStatus: number;
  cTitle: string;
  cUpdateDt: string;
  cUpdator: string;
  id: number;
}

export class QuestionType {
  companyId: string
  id: number
  name: string
  status: number
  upDate: string
}

export class ContactUsReq {
  CType: string = "0";
  COrderNumber: string;
  CName: string
  CEmail: string
  CPhone: string
  CSex: number
  CTitle: string
  CContent: string
  Img1: string = ""
  Img2: string = ""
  Img3: string = ""
  Img4: string = ""
  Img5: string = "";
  RecaptchaToken?: string;
  captchaCode: string;
}
export class GetQAList {
  CQatypeId?: number;
}
