<div class="top-btn" [ngClass]="{'top-btn--hide': isTopBtnHide}">
    <a href="#/" (click)="onTopBtnClick($event)">
        <img src="/assets/images/icashpay-icon/top.svg" alt="">
        <!-- <span>TOP</span> -->
    </a>
</div>
<!-- 
<div class="home-btn" [ngClass]="{'top-btn--hide': isTopBtnHide}">
  <a [routerLink]="'/question/contact'" >
      <span><img  src="/assets/images/icon/customer_se.svg" alt=""></span>
  </a>
</div> -->
