<div class="product-background">
  <section class="product-content">
    <div class="bottom">
      <div class="product-content__fixed-info-wrap">
        <!-- <app-product-detail-info [isShow]="isInfoShow" [product]="!!product.label" [relatedNews]="relatedNews"
            (closeInfo)="isInfoShow=$event">
          </app-product-detail-info> -->
      </div>
      <div class="product-content__label">
        {{ticket.title}}
      </div>
      <!-- <div class="text-section__expiration">
        <p>{{ticket.title}}</p>
      </div> -->
      <section class="product-content__info">
        <div class="product-content__row">
          <div class="product-content__col product-content__col--text-section">
           <div class="thebarcode">
                <!-- //序號券 barcode128 -->
                <ngx-barcode style="
                max-width: 100%;
                margin-bottom: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
              " *ngIf="ticket.couponType==1 && ticket.barCodeType ==1" [bc-value]="ticket.ticketNo"
                  [bc-display-value]="true">
                </ngx-barcode>
                  <!-- //序號券 barcode39 -->
                <ngx-barcode style="
              max-width: 100%;
              margin-bottom: 0px;
              display: flex;
              justify-content: center;
              align-items: center;
            " *ngIf="ticket.couponType==1 && ticket.barCodeType ==2" [bc-format]="'CODE39'"
                  [bc-value]="ticket.ticketNo" [bc-display-value]="true">
                </ngx-barcode>
           </div>
           <!-- 序號券 純序號 -->
            <div class="text-section__title-wrap">
              <h3 *ngIf="ticket.couponType == 1 && ticket.barCodeType == 3" class="text-section__title">
                {{ticket.ticketNo}}</h3>
              <!-- barcode -->
              <div class="ticket-use-modal__bar-code-wrap">
                <!-- 下方喜客券 -->
                <ngx-barcode style="
                  max-width: 100%;
                  margin-bottom: 0px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " *ngIf="ticket.isQR == 0" [bc-value]="ticket.ticketNo" [bc-display-value]="true">
                </ngx-barcode>
                <ngx-barcode style="
                  max-width: 100%;
                  margin-bottom: 0px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " *ngIf="ticket.isQR == 39" [bc-value]="ticket.ticketNo" [bc-display-value]="true" bc-format="CODE39">
                </ngx-barcode>
                <ngx-qrcode style="
                  max-width: 100%;
                  margin-bottom: 0px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " *ngIf="ticket.isQR == 1" [value]="ticket.qRcodeString" [width]="300">
                </ngx-qrcode>
                <!-- <p *ngIf="ticket.isQR == 1" style="
                  max-width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                  {{ ticket.couponNo }}
                </p> -->
                <p *ngIf="ticket.isQR == 3">
                  PIN碼:{{ ticket.pinCode }}
                </p>
                <ngx-barcode style="
                  max-width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " *ngIf="
                  ticket.isQR == 2 || ticket.isQR == 3
                " [bc-value]="ticket.barcode1" [bc-display-value]="true"></ngx-barcode>
                <ngx-barcode style="
                  max-width: 100%;
                  margin-bottom: 8px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " *ngIf="ticket.isQR == 2" [bc-value]="ticket.barcode2" [bc-display-value]="true">
                </ngx-barcode>
                <ngx-barcode style="
                  max-width: 100%;
                  margin-bottom: 8px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " *ngIf="ticket.isQR == 4" [bc-value]="ticket.multiBarcodeType" [bc-display-value]="true">
                </ngx-barcode>
                <ngx-barcode style="
                  max-width: 100%;
                  margin-bottom: 0px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " *ngIf="ticket.isQR == 4" [bc-value]="ticket.couponNo" [bc-display-value]="true">
                </ngx-barcode>
              </div>
              <!-- <ngx-barcode6
                  style="max-width: 100%;margin-bottom:12px;display: flex;justify-content: center;align-items: center;"
                  *ngIf="ticket.isQR ==2"
                  [bc-value]="ticket.barcode1" [bc-display-value]="true"></ngx-barcode6>     -->
            </div>
            <div class="text-section__sn" *ngIf="ticket.couponType==1 && ticket.barCodeType ==3">
              <p (click)="copyLink()">
                <img class="product-content__copy" src="/assets/images/icashpay-icon/copy_icon.svg" alt="">
                複製
              </p>
            </div>

            <div class="text-section__point">
              <div>兌換期限：{{ ticket.useLimitDT | date: "yyyy/MM/dd" }}</div>
              <div *ngIf="ticket.couponType==3||ticket.couponType==1 && ticket.barCodeType==1||ticket.couponType==1 && ticket.barCodeType==2" style="margin-top: 4px">票券序號：{{ ticket.ticketNo }}</div>

            </div>

            <div class="row text-section__add-to-cart-section">
              <div class="col-12 d-flex justify-content-between"
                [ngClass]="{'justify-content-center':ticket.couponType ==3}">
                <a class="process-step-button__button process-step-button__button--cancel" (click)="goTicket()">返回票夾</a>
                <div style="min-width: 20px;"></div>
                <button #addToCartBtn class="text-section__button text-section__button--primary"
                  *ngIf="ticket.couponType !==3" mat-raised-button (click)="isPointModalOpen = true">使用
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <article class="product-content__note">
      <div class="note__warning-list">
        <div class="warning-title">票券注意事項</div>
        <div [innerHTML]="ticket.ticketPrecautions | html"></div>
      </div>
    </article>
    <div class="recommended-line"></div>
  </section>
</div>


<!--使用票券提示-->
<app-modal [isModalOpen]="isPointModalOpen" (modalClose)="isPointModalOpen=$event">
  <h3 headerContent class="general-modal__title__privacy" style="margin-bottom: 0;">
    <img style="width: 56px; height: 56px;" src="/assets/images/icashpay-icon/dialog.png" alt="">
  </h3>
  <div bodyContent class="privacy-modal__content-wrap">
    <div>請確認是否要使用票券<br>提醒您，一次性使用的票券在核銷後將不可恢復。</div>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--cancel" (click)="isPointModalOpen = false;">取消</button>
    <button class="general-modal__button general-modal__button--confirm" (click)="send()">送出</button>
  </div>
</app-modal>


<app-modal [isModalOpen]="isModalOpenByCopy" (modalClose)="isModalOpenByCopy=$event">
  <h3 headerContent class="general-modal__title__privacy" style="margin-bottom: 0;">
    <img style="width: 56px; height: 56px;" src="/assets/images/icashpay-icon/dialog.png" alt="">
  </h3>
  <div bodyContent class="privacy-modal__content-wrap">
    <div style="width: 300px;">複製成功</div>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--confirm"
      (click)="isModalOpenByCopy = false;">確認</button>
  </div>
</app-modal>
