import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SwiperModule } from 'swiper/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { TopBtnComponent } from './shared/components/top-btn/top-btn.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { TabNavbarComponent } from './shared/components/tab-navbar/tab-navbar.component';
import { MemberCartComponent } from './views/member/member-cart/member-cart.component';
import { MemberOrderComponent } from './views/member/member-order/member-order.component';
import { ProductListComponent } from './views/product/product-list/product-list.component';
import { ProductDetailComponent } from './views/product/product-detail/product-detail.component';
import { ProductOverviewComponent } from './views/product/product-overview/product-overview.component';
import { HomeComponent } from './views/home/home.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { MemberComponent } from './views/member/member.component';
import { MemberPointComponent } from './views/member/member-point/member-point.component';
import { MemberPointTransferComponent } from './views/member/member-point/member-point-transfer/member-point-transfer.component';
import { MemberTicketComponent } from './views/member/member-ticket/member-ticket.component';
import { MemberVoucherComponent } from './views/member/member-voucher/member-voucher.component';
import { MemberFavoriteComponent } from './views/member/member-favorite/member-favorite.component';
import { LoginService } from './shared/service/login.service';
import { HttpService } from './shared/service/http.service';
import { HttpClientModule } from '@angular/common/http';
import { TabNavbarModalComponent } from './shared/components/tab-navbar/tab-navbar-modal/tab-navbar-modal.component';
import { HeaderInfoComponent } from './shared/components/header/header-info/header-info.component';
import { ProductDetailInfoComponent } from './views/product/product-detail/product-detail-info/product-detail-info.component';
import { FormsModule } from '@angular/forms';
import { HtmlPipe } from './shared/pipe/html.pipe';
import { PaginationComponent } from './shared/components/pagination/pagination.component';
import { CarouselComponent } from './shared/components/carousel/carousel.component';
import { TeamCarouselComponent } from './shared/components/teamCarousel/teamCarousel.component';
import { EventComponent } from './views/event/event.component';
import { EventListComponent } from './views/event/event-list/event-list.component';
import { ProductComponent } from './views/product/product.component';
import { EventDetailComponent } from './views/event/event-detail/event-detail.component';
import { MemberCartResultComponent } from './views/member/member-cart/member-cart-result/member-cart-result.component';
import { CartService } from './shared/service/cart.service';
import { LoaderService } from './shared/service/loader.service';
import { HideUsernamePipe } from './shared/pipe/hide-username.pipe';
import { HomeLoginComponent } from './views/home/home-login/home-login.component';
import { HomeRegisterComponent } from './views/home/home-register/home-register.component';
import {  RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings} from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { MemberOrderDetailComponent } from './views/member/member-order/member-order-detail/member-order-detail.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { DragScrollModule } from 'ngx-drag-scroll';
import { QuestionComponent } from './views/question/question.component';
import { QuestionContactComponent } from './views/question/question-contact/question-contact.component';
import { QuestionListComponent } from './views/question/question-list/question-list.component';
import { BreadcrumbService } from './shared/service/breadcrumb.service';
import { QuestionService } from './shared/service/question.service';
import { AuthGuard } from './shared/guards/auth-guard';
import { HomeSitemapComponent } from './views/home/home-sitemap/home-sitemap.component';
import { ShutdownGuard } from './shared/guards/shutdown-guard';
import { HomeShutdownComponent } from './views/home/home-shutdown/home-shutdown.component';
import { ShutdownService } from './shared/service/shutdown.service';
import { ScriptService } from './shared/service/script.service';
import { PagetagService } from './shared/service/pagetag.service';
import { ParentBrandService } from './shared/service/parent-brand.service';
import { TicketLinkComponent } from './views/ticket-link/ticket-link.component';
import { ColophonComponent } from './shared/components/colophon/colophon.component';
import { PrivacyComponent } from './shared/components/privacy/privacy.component';
import { TremsComponent } from './shared/components/trems/trems.component';
import { HeaderService } from './shared/service/header.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlCarouselComponent } from './shared/components/owl-carousel/owl-carousel.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MemberTicketDetailComponent } from './views/member/member-ticket-detail/member-ticket-detail.component';
import { ActivitySigninComponent } from './views/activity/activity-signin/activity-signin.component';
import { ActivityComponent } from './views/activity/activity.component';
import { ActivityLogComponent } from './views/activity/activity-log/activity-log.component';
import { ActivityLotteryComponent } from './views/activity/activity-lottery/activity-lottery.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    TopBtnComponent,
    BreadcrumbComponent,
    TabNavbarComponent,
    MemberCartComponent,
    MemberOrderComponent,
    ProductListComponent,
    ProductDetailComponent,
    ProductOverviewComponent,
    HomeComponent,
    ModalComponent,
    MemberComponent,
    MemberPointComponent,
    MemberPointTransferComponent,
    MemberTicketComponent,
    MemberVoucherComponent,
    MemberFavoriteComponent,
    TabNavbarModalComponent,
    HeaderInfoComponent,
    ProductDetailInfoComponent,
    HtmlPipe,
    PaginationComponent,
    CarouselComponent,
    TeamCarouselComponent,
    EventComponent,
    EventListComponent,
    ProductComponent,
    EventDetailComponent,
    MemberCartResultComponent,
    HideUsernamePipe,
    HomeLoginComponent,
    HomeRegisterComponent,
    MemberOrderDetailComponent,
    QuestionComponent,
    QuestionContactComponent,
    QuestionListComponent,
    HomeSitemapComponent,
    HomeShutdownComponent,
    TicketLinkComponent,
    ColophonComponent,
    PrivacyComponent,
    TremsComponent,
    OwlCarouselComponent,
    MemberTicketDetailComponent,
    ActivitySigninComponent,
    ActivityComponent,
    ActivityLogComponent,
    ActivityLotteryComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    NgxBarcode6Module,
    NgxBarcodeModule,
    NgxQRCodeModule,
    DragScrollModule,
    SwiperModule,
    CarouselModule
  ],
  providers: [
    { provide: LoginService },
    { provide: HttpService },
    { provide: CartService },
    { provide: LoaderService },
    { provide: BreadcrumbService },
    { provide: QuestionService },
    { provide: AuthGuard },
    { provide: ShutdownGuard },
    { provide: ShutdownService },
    { provide: ScriptService },
    { provide: PagetagService },
    { provide: ParentBrandService },
    { provide: HeaderService },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey:  environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: {
    //     siteKey: environment.recaptcha.siteKey,
    //   } as RecaptchaSettings,
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
